import React, { useState } from "react";
import {
  Typography,
  TextField,
  MenuItem,
  Button,
  Avatar,
} from "@material-ui/core";

import Checkbox from "@material-ui/core/Checkbox";
// import FacebookLogin from "react-facebook-login";
// import SocialLoginComponent from "../../../../utility/SocialLoginComponent";
import useAxios from "../../../../utility/axios-token-manager/init";
import Flash from "../../../../utility/Flash";
import { loadStart, loadStop } from "../../../../redux/actions/loading";
import { useDispatch } from "react-redux";
import { ImageResizer } from "../../../ImageResizer";
// import { allCategory } from "./category";
//
export default function Form() {
  const dispatch = useDispatch();
  // const [country, setCountry] = useState(allCountries()[0]);

  //coverage

  const [inputs, setInputs] = useState({
    fullName: "",
    email: "",
    mobile: "",
    address: "",
    nok: "",
    nokAddress: "",
    nokPhone: "",
    nokRelationship: "",
    id: null,
    password: "",
    password2: "",
  });

  const handleChange = (e) => {
    inputs[e.target.name] = e.target.value;
    setInputs({ ...inputs });
  };
  // validation
  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleSubmit = async () => {
    if (!validateEmail(inputs.email.trim()))
      return Flash("error", "Invalid email", "", 3000);
    if (inputs.password.trim() !== inputs.password2.trim())
      return Flash("error", "Password do not match", "", 3000);
    if (
      !inputs.password.trim() ||
      !inputs.fullName.trim() ||
      !inputs.address.trim() ||
      !inputs.mobile.trim() ||
      !inputs.nok.trim() ||
      !inputs.nokAddress.trim() ||
      !inputs.nokPhone.trim() ||
      !inputs.nokRelationship.trim()
    )
      return Flash("error", "All fields are required", "", 3000);

    // if (!inputs.id)
    //   return Flash("error", "Please upload a valid ID card", "", 3000);
    if (inputs.password.trim().length < 8) return Flash("error", "Password must be atleast 8 characters", "", 3000);
    const data = {
      ...inputs,
      mobile: `${inputs.mobile}`,
      email: `${inputs.email.trim().toLowerCase()}`,
    };
    dispatch(loadStart());
    try {
      const response = await useAxios.post("/auth/agent/sign-up", data);
      dispatch(loadStop());
      if (response.data.code === 401)
        return Flash("error", "Email already in use", "", 3000);
      if (response.data.code === 201)
        Flash("success", "Account created successfully", "", 3000);
      setTimeout(() => {
        window.location.assign("/accounts?q=login");
      }, 2000);
    } catch (error) {
      dispatch(loadStop());
      return Flash("error", error.message, "", 3000);
    }
  };

  // check
  const [agree, setAgree] = useState(false);

  const handleAgree = (e) => {
    setAgree(e.target.checked);
  };

  //
  const uploadToS3 = async (file, field) => {
    let img = file;
    if (file.size > 300000) {
      img = await ImageResizer(file);
    }
    try {
      const resizeImg = await ImageResizer(img);
      const image = new FormData();
      image.append("image", resizeImg);
      dispatch(loadStart());
      useAxios
        .post("/upload/affiliates", image, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          dispatch(loadStop());
          if (res.data.code === 201) {
            inputs[field] = res.data.data;
            setInputs({ ...inputs });
            // handleUpdateUpload(field, res.data.data);
          }
        })
        .catch((e) => {
          dispatch(loadStop());
          Flash("error", "An error occured", "Error", 5000);
        });
    } catch (error) {
      Flash("error", "An error occured", "Error", 5000);
    }
  };

  const handleUpload = async (e, field) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      if (!file.type.split("/").includes("image"))
        return Flash("error", "Image format should be jpg/png", "", 3000);
      await uploadToS3(file, field);
    } else {
      return Flash("error", "Abort", "", 3000);
    }
  };

  return (
    <div style={{ marginTop: "100px" }}>
      <Typography variant="h6" className="text-center mb-2">
        Sales Agent Application Form
      </Typography>
      <Typography variant="body2" className="text-center mb-2">
        Thank you for your interest in our Marketing Programme. Please fill the
        form below to get started.
      </Typography>
      <div>
        <Typography style={{ color: "red", marginTop: "30px" }}>
          All fields are required
        </Typography>
        <Typography variant="h6" className="mt-4 color-blur">
          Bio Data
        </Typography>
        <div className="row">
          <div className="col-lg-12 mt-2 mb-3">
            <TextField
              label="Fullname"
              fullWidth
              name="fullName"
              autoComplete="Off"
              value={inputs.fullName}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 mb-3">
            <TextField
              label="Email"
              fullWidth
              name="email"
              autoComplete="Off"
              value={inputs.email}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 mb-3">
            <TextField
              label="Mobile Number"
              fullWidth
              name="mobile"
              autoComplete="Off"
              value={inputs.mobile}
              type="number"
              onChange={(e) => {
                if (e.target.value.length > 11) return null;
                handleChange(e);
              }}
            />
          </div>
          <div className="col-lg-6 mb-3">
            <TextField
              label="Residential Address"
              fullWidth
              name="address"
              autoComplete="Off"
              value={inputs.address}
              onChange={handleChange}
            ></TextField>
          </div>
        </div>
        {/* <div style={{ marginBottom: "30px" }}>
          <Typography className="color-blur mb-2" variant="body1">
            Means of Identification
          </Typography>
          <div>
            <TextField
              type="file"
              helperText="Please upload valid means of identification in jpeg/png format"
              onChange={(e) => handleUpload(e, "id")}
            />
            {inputs.id ? (
              <Avatar
                src={inputs?.id?.Location}
                style={{ width: "120px", height: "120px" }}
                variant="rounded"
              />
            ) : null}
          </div>
        </div> */}
        <div>
          <Typography variant="h6" className="color-blur">
            Next of Kin Details
          </Typography>
          <div className="row">
            <div className="col-lg-6">
              <TextField
                label="Next of Kin"
                name="nok"
                value={inputs.nok}
                fullWidth
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6">
              <TextField
                label="Relationship"
                fullWidth
                select
                name="nokRelationship"
                value={inputs.nokRelationship}
                onChange={handleChange}
              >
                {["Brother", "Sister", "Son", "Daughter", "Wife", "Husband"]
                  .sort()
                  .map((item, index) => (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className="col-lg-6">
              <TextField
                label="Address"
                fullWidth
                name="nokAddress"
                value={inputs.nokAddress}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6">
              <TextField
                label="Phone Number"
                type="number"
                name="nokPhone"
                value={inputs.nokPhone}
                fullWidth
                onChange={(e) => {
                  if (e.target.value.length > 11) return null;
                  handleChange(e);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "20px" }}>
          <Typography variant="h6" className="col-lg-12 color-blur">
            Create Password
          </Typography>
          <div className="col-lg-6">
            <TextField
              label="Password"
              fullWidth
              type="password"
              name="password"
              autoComplete="Off"
              value={inputs.password}
              onChange={handleChange}
              helperText="Minimum of 8 characters"
            />
          </div>
          <div className="col-lg-6">
            <TextField
              label="Confirm Password"
              fullWidth
              type="password"
              name="password2"
              autoComplete="Off"
              value={inputs.password2}
              onChange={handleChange}
              helperText="Minimum of 8 characters"
            />
          </div>
        </div>
        <div className="mb-4 mt-4 policy-disclaimer color-blur">
          <label>
            To process your application, you’ll need to agree to the{" "}
            <a href="/terms_conditions">Terms &amp; Condition</a>. We process
            your information as described in our{" "}
            <a href="/privacy">Privacy Policy</a>.
          </label>
        </div>
        <div className="d-flex align-items-center">
          <Checkbox checked={agree} onChange={handleAgree} />{" "}
          <Typography variant="body1">
            I've read and agreed to the terms of service.
          </Typography>
        </div>
        <div style={{ marginTop: "40px", marginBottom: "40px" }}>
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              <Button
                disabled={!agree}
                className="w-100 "
                style={{ background: "#A7CC48" }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
