import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Avatar,
  Button,
  MenuItem,
  Paper,
  Typography,
  TextField,
} from "@material-ui/core";
import styles from "./styles.module.css";
import Earnings from "../tables/Earnings";
import Referrals from "../tables/Refs";
import useAxios from "../../../utility/axios-token-manager/init";
import { setUpdate } from "./../../../redux/actions/profile";
import { loadStart, loadStop } from "./../../../redux/actions/loading";
import SECTORS, { sections } from "./../sectors";
import Flash from "../../../utility/Flash";
import SupervisedAgents from "../tables/SupervisedAgents";
import { getValidToken, rememberToken } from "../../../utility/axios-token-manager/token";
//
function Profile({ agents, id }) {
  const dispatch = useDispatch();
  //

  const [agent, setAgent] = useState({});
  const [merchants, setmerchants] = useState([])
  const [totalSales, settotalSales] = useState(0)


  const lastLogin = () => {
    const logins = agent?.logins;
    if (logins?.length > 0) {
      return logins[logins.length - 1]
    } else {
      return { lat: "", long: "", address: "", time: 0 }
    }
  }
  const handleTotalSales = () => {
    const earns = agent?.earnings?.map(item => item.amount).reduce((a, b) => parseInt(a) + parseInt(b), 0);
    if (earns) return `${(earns / 100).toLocaleString()}`
    return `0`
  }

  const getAgentMerchant = async (code) => {
    try {
      const response = await useAxios.get(`/sales-agent/my-merchant/${code}`)
      const { success, message, merchants } = response.data
      if (success == true) {
        let total = 0
        merchants.map(item => {
          item.payment.map((payment, index) => {
            if (index === 0) {
              return total += payment.price
            }
          })
        })
        settotalSales(parseInt(total))
        setmerchants(merchants.filter(item => item.payment.length > 0 && item))
        dispatch(loadStop());
      }
      else {
        Flash("warning", message, "", 3000);
        dispatch(loadStop());
      }
    } catch (error) {
      Flash("error", error.message, "", 3000);
      dispatch(loadStop());
    }
  }
  useEffect(() => {
    const profile = agents.filter((item) => item._id === id);
    dispatch(loadStart());
    profile[0].approve === true ? getAgentMerchant(profile[0].agentCode) : dispatch(loadStop());
    setAgent(profile[0]);
  }, [agents, id]);


  const [show, setShow] = useState("profile");

  const handleUpdate = async (state) => {
    try {
      dispatch(loadStart());
      const response = await useAxios.put(`/excite/agent/update/${agent._id}`, {
        state: state,
      });
      const { success, agents, message } = response.data
      if (success === true) {
        dispatch(setUpdate(agents));
        Flash("success", message, "", 3000);
        dispatch(loadStop());
      }
      else {
        dispatch(setUpdate(agents));
        Flash("warning", message, "", 3000);
        dispatch(loadStop());
      }
    } catch (error) {
      dispatch(loadStop());
      Flash("error", error.message, "", 3000);
    }
  };

  //
  const [sector, setSector] = useState(agent?.sector);
  const handleSectorChange = (e) => {
    setSector(e.target.value);
  };

  const handleAssign = async () => {
    if (parseInt(agent?.sector) === parseInt(sector))
      return Flash("info", "Sector has not changed", "", 3000);
    try {
      dispatch(loadStart());
      const response = await useAxios.put(
        `/excite/agent/sector/assign/${agent._id}`,
        {
          sector: SECTORS[sector]?.code,
        }
      );
      dispatch(loadStop());
      if (response.data.code === 200) {
        dispatch(setUpdate(response.data.agents));
        Flash("success", "Sector Assigned", "", 3000);
      }
    } catch (error) {
      dispatch(loadStop());
      Flash("error", "An error occured", "", 3000);
    }
  };

  const handleLoginDate = (time) => {
    if (time === 0) return 'This User is yet to login'
    const day = new Date(time);
    return day.toLocaleString()
  }
  //
  return (
    <>
      <div className="mb-4 d-flex">
        <div
          className={show === "profile" ? "active-filter " : "btn-filter"}
          style={{ marginRight: "20px" }}
        >
          <Button onClick={() => setShow("profile")}>Agent Profile</Button>
        </div>
        <div className={show === "earnings" ? "active-filter" : "btn-filter"}>
          <Button
            className={show === "earnings" ? styles.active : styles.btn}
            onClick={() => setShow("earnings")}
          >
            Sales & Comissions
          </Button>
        </div>
      </div>
      {show === "profile" ? (
        <>
          <Paper className={styles.paper}>
            <div>
              <Typography className="text-center" variant="h5">
                Sales Agent Profiles
              </Typography>

              <div className="row">
                <div className="col-lg-4">
                  <Avatar
                    style={{ width: "100px", height: "100px" }}
                    src={agent?.passport?.Location}
                  />
                  {agent?.id?.Location ? (
                    <Button className="mt-2">
                      <a download href={agent?.id?.Location}>
                        Download ID
                      </a>
                    </Button>
                  ) : (
                    <Button className="mt-2">No ID</Button>
                  )}
                </div>
                <div className="col-lg-3 mt-4 mt-lg-auto">
                  <Typography variant='h6' >Reference Code</Typography>
                  {/* <br></br> */}
                  <Button>
                    {agent?.approve ? agent?.agentCode : "Pending"}
                  </Button>
                </div>
                <div className="col-lg-5 mt-4 mt-lg-auto">
                  <div className="d-flex">
                    <Button
                      style={{
                        color: "white",
                        background: "#a7cc48",
                        marginRight: "20px",
                      }}
                      disabled={agent?.approve}
                      onClick={() => handleUpdate(true)}
                    >
                      Approve
                    </Button>
                    <Button
                      style={{ color: "white", background: "red" }}
                      disabled={!agent?.approve}
                      onClick={() => handleUpdate(false)}
                    >
                      Decline
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className="row"
                style={{ marginTop: "50px", marginBottom: "50px" }}
              >
                <div className="col-lg-6">
                  {agent?.sector ? (
                    <Typography variant="h6" className='mb-4'>
                      Assigned to Sector {agent?.sector}
                    </Typography>
                  ) : (
                    <Typography style={{ color: "red" }} variant="body2" className='mb-4'>
                      Assign to a Sector
                    </Typography>
                  )}
                  <TextField
                    value={sector}
                    select
                    fullWidth
                    className="mt-2 mb-3"
                    variant="outlined"
                    label={agent?.sector ? "Reassign to a sector" : "Assign to Sector"}
                    onChange={handleSectorChange}
                  >
                    {sections.map((item, index) => (
                      <MenuItem
                        value={item}
                        key={index}
                      >{`Sector ${item}`}</MenuItem>
                    ))}
                  </TextField>
                  <div style={{ background: "#a7cc48" }}>
                    <Button fullWidth onClick={() => handleAssign()}>
                      Confirm
                    </Button>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <Typography variant='h6' className='mb-4'>Agent Location</Typography>
                  <table className='table'>
                    <tbody>
                      <tr>
                        <td>Date</td>
                        <td>{lastLogin() ? handleLoginDate(lastLogin().time) : "No data"}</td>
                      </tr>
                      <tr>
                        <td>Longitude</td>
                        <td>{lastLogin() ? lastLogin().long : "No data"}</td>
                      </tr>
                      <tr>
                        <td>Latitude</td>
                        <td>{lastLogin() ? lastLogin().lat : "No data"}</td>
                      </tr>
                      <tr>
                        <td>Address</td>
                        <td>{lastLogin() ? lastLogin().address : "No data"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-4">
                <Typography variant="h6">Bio Data</Typography>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Full Name</td>
                      <td>{agent?.fullName}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{agent?.email}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{agent?.address}</td>
                    </tr>
                    <tr>
                      <td>Phone Number</td>
                      <td>{agent?.mobile}</td>
                    </tr>
                    <tr>
                      <td>NOK Full Name</td>
                      <td>{agent?.nok}</td>
                    </tr>
                    <tr>
                      <td>NOK Address</td>
                      <td>{agent?.nokAddress}</td>
                    </tr>
                    <tr>
                      <td>NOK Relationship</td>
                      <td>{agent?.nokRelationship}</td>
                    </tr>
                    <tr>
                      <td>NOK Phone Number</td>
                      <td>{agent?.nokPhone}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div>My profile {agent?.fullName}</div> */}
          </Paper>
          <Paper className={styles.paper + " mt-4"}>
            <div>
              <Typography variant="h6" className="mb-4">
                Bank Details
              </Typography>
              <table className="table">
                <tbody>
                  <tr>
                    <td>Bank Name</td>
                    <td>{agent?.accountDetails?.bank}</td>
                  </tr>
                  <tr>
                    <td>Account Number</td>
                    <td>{agent?.accountDetails?.accountNo}</td>
                  </tr>
                  <tr>
                    <td>Account Name</td>
                    <td>{agent?.accountDetails?.accountName}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>{" "}
        </>
      ) : null}
      {show === "earnings" ? (
        <>
          <div className="row-statistic">
            <div className=" statistic-item">
              <Typography className="mb-2">Total Referrals</Typography>
              <Typography variant="h6">{agent?.merchants?.length || 0}</Typography>
            </div>
            <div className="  statistic-item">
              <Typography className="mb-2">Total Sales</Typography>
              <Typography variant="h6"> <span>&#8358; </span> {totalSales.toLocaleString()}</Typography>
            </div>
          </div>
          <div className={" mt-4 table-wrapper"}>
            <Earnings agent={merchants} />
          </div>
          <div className={" mt-4 table-wrapper"}>
            <Referrals agent={agent} />
          </div>
          <div className={" mt-4 table-wrapper"}>
            {agent.isLead ?
              <SupervisedAgents agent={agent} /> : null}
          </div>
        </>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    agents: state?.profile?.agents,
  };
};

export default connect(mapStateToProps)(Profile);
