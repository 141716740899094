import "./App.css";
import { Switch, Route } from "react-router-dom";
import Home from "./components/Landing/Index";
import Account from "./components/accounts/Index";
import ApiLoader from "./utility/ApiLoader";
import { connect } from "react-redux";
import { NotificationContainer } from "react-notifications";
import Landing from "./components/dashboard/Landing";
import Restricted from "./components/Restricted/Index";
import AdminLogin from "./components/Restricted/accounts/Index";
import Sectors from "./components/Restricted/sectors/Index";
import ExtFilter from "./components/RestrictedExt/sectors/Index";
import Feedback from "./components/Restricted/feedbacks/Index";
import Reset from "./components/accounts/Password/Reset";
import Confirm from "./components/accounts/Password/Confirm";


// External Sales Access
import RestrictedExt from "./components/RestrictedExt/Index";
import AdminLoginExt from "./components/RestrictedExt/accounts/Index";

//
function PrivateRoute({ component: Component, authed, ...rest }) {
  const REACT_APP_GEOMAPPING = process.env.REACT_APP_GEOMAPPING
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} />
        ) : (
          window.location.assign("/accounts?q=login")
        )
      }
    />
  );
}
//
function PrivateRouteAdmin(props) {
  const { component: Component, authed, ...rest } = props
  return (
    <Route
      {...rest}
      render={(props1) => {
        return authed === true ? (
          <Component {...props1} />
        ) : (
          window.location.assign("/admin/accounts?q=login")
        )
      }
      }
    />
  );
}

function PrivateRouteExt({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} />
        ) : (
          window.location.assign("/brand-dynamo/accounts?q=login")
        )
      }
    />
  );
}

function App({ token, isLogged, auth }) {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/accounts" component={Account}></Route>
        <Route exact path="/admin/accounts" component={AdminLogin}></Route>
        <Route exact path="/brand-dynamo/accounts" component={AdminLoginExt}></Route>
        <Route exact path="/password-reset" component={Reset}></Route>
        <Route exact path="/reset" component={Confirm}></Route>
        <PrivateRouteAdmin
          exact
          authed={
            isLogged && token.length > 0 && auth.auth.userType === "EXMANAF"
          }
          path="/admin"
          component={Restricted}
        ></PrivateRouteAdmin>
        <PrivateRouteAdmin
          exact
          authed={
            isLogged && token.length > 0 && auth.auth.userType === "EXMANAF"
          }
          path="/filter"
          component={Sectors}
        ></PrivateRouteAdmin>
        <PrivateRouteAdmin
          exact
          authed={
            isLogged && token.length > 0 && auth.auth.userType === "EXMANAF"
          }
          path="/feedback"
          component={Feedback}
        ></PrivateRouteAdmin>
        <PrivateRoute
          exact
          authed={
            isLogged && token.length > 0 && auth.auth.userType === "EX20AG"
          }
          path="/console"
          component={Landing}
        />
        {/* External Sales Private Route */}
        <PrivateRouteExt
          exact
          authed={
            isLogged && token.length > 0 && auth.auth.userType === "EX-DSA-EXT-ADMIN"
          }
          path="/brand-dynamo"
          component={RestrictedExt}
        ></PrivateRouteExt>
        <PrivateRouteExt
          exact
          authed={
            isLogged && token.length > 0 && auth.auth.userType === "EX-DSA-EXT-ADMIN"
          }
          path="/brand-dynamo/filter"
          component={ExtFilter}
        ></PrivateRouteExt>
      </Switch>
      <ApiLoader />
      <NotificationContainer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    isAuth: state.auth.token !== null,
    isLogged: state.auth.isLogged,
    email: state.auth.email,
    loading: state.loading.isLoading,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(App);
