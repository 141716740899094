import {
  Typography,
  Paper,
  TextField,
  Select,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "./../styles.module.css";
import bankList from "../../../../bankList";
import { connect, useDispatch } from "react-redux";
import useAxios from "../../../../../utility/axios-token-manager/init";
import Flash from "../../../../../utility/Flash";
import {setUpdate} from "./../../../../../redux/actions/profile"
import { loadStart,loadStop } from "../../../../../redux/actions/loading";

//
function Index({ accountDetails }) {
const dispatch = useDispatch()
  const [edit, setEdit] = useState(false);
  const [accounts, setAccounts] = useState({
    bank: "",
    accountNo: "",
    accountName: "",
  });

  const handleChange = (e) => {
    accounts[e.target.name] = e.target.value;
    setAccounts({ ...accounts });
  };

  useEffect(() => {
    setAccounts({ ...accountDetails });
  }, [accountDetails]);

  const handleUpdateAccount = async()=>{
      if(JSON.stringify(accountDetails) === JSON.stringify(accounts)) return Flash('info','No changes made','',3000)
      try {
          dispatch(loadStart())
        const response =await useAxios.put('/sales-agent/my-settings/bank', {...accounts})
        dispatch(loadStop())
        if(response.data.code===201){
            dispatch(setUpdate(response.data.profile))
            Flash('success','Bank information updated','',3000)
            setTimeout(()=>{
              window.location.reload()
            },2000)
        }else{
            return Flash('error','Update failed','',3000)
        }
      } catch (error) {
        dispatch(loadStop())
        return Flash('error','Server error','',3000)
      }
  }
  //
  return (
    <Paper className={styles.paper}>
      <Typography variant="h6" className="mt-4 text-center">
        Bank information
      </Typography>
      <div>
        <Typography className="color-blur mb-2" variant="body2">
          Bank Name
        </Typography>
        <Select
          native={true}
          label="Bank Name"
          value={accounts.bank}
          name="bank"
          onChange={edit ? handleChange : null}
          fullWidth
          inputProps={{
            readOnly: !edit,
          }}
        >
          {bankList.map((item, index) => (
            <option key={index}>{item}</option>
          ))}
        </Select>
      </div>
      <div>
        <Typography className="color-blur mb-2 mt-4" variant="body2">
          Account Number
        </Typography>
        <TextField
          fullWidth
          value={accounts.accountNo}
          type="number"
          onChange={(e)=>{
              if(e.target.value.length > 11) return null
              return handleChange(e)
            }}
          name="accountNo"
          inputProps={{
            readOnly: !edit,
          }}
        />
      </div>
      <div>
        <Typography className="color-blur mb-2 mt-4" variant="body2">
          Account Name
        </Typography>
        <TextField
          fullWidth
          value={accounts.accountName}
          onChange={handleChange}
          name="accountName"
          inputProps={{
            readOnly: !edit,
            maxLength:100
          }}
        />
      </div>
      <div
        className="bg-pry"
        style={{ marginTop: "40px", background: "#a7cc48" }}
      >
        {!edit ? (
          <Button fullWidth onClick={() => setEdit(true)}>
            Click to edit
          </Button>
        ) : (
          <Button fullWidth onClick={handleUpdateAccount}>Click to Update</Button>
        )}
      </div>
    </Paper>
  );
}
const mapStateToProps = (state) => {
  return {
    accountDetails: state.profile?.userProfile?.accountDetails,
  };
};

export default connect(mapStateToProps)(Index);
