import React, { useState, useEffect, useMemo, useLayoutEffect } from "react";
import DataTable from "react-data-table-component";
// import Button from '../shared/Button';
import { Card, Button, Paper, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import ExportCSV from "./../../ExcelDownloader";
import Flash from "./../../../utility/Flash";
import { host } from "../../../utility/host";
import { useDispatch } from "react-redux";
import { loadStart, loadStop } from "./../../../redux/actions/loading";
import Next from '../../asset/img/next.png'
import Prev from '../../asset/img/prev.png'
import Right from '../../asset/img/right.png'
import Left from '../../asset/img/left.png'
import SubscriptionModal from "./SubscriptionModal";
import { getMerchants } from "../../../redux/actions/profile";
import useAxios from "../../../utility/axios-token-manager/init";



function handleTotalSales(agent) {
  let earns = agent.earnings
    .map((item) => item.amount)
    .reduce((a, b) => Number(a) + Number(b), 0);
  if (earns) {
    return `${(earns / 100).toLocaleString()}`;
  } else {
    return `0`;
  }
}
const handleGetSupervision = (code, agents) => {
  const superDetails = agents.find((item) => item.agentCode === code);
  if (superDetails) return superDetails.fullName;
  return "";
};



const Table = ({ exportData, admin_token, merchants, getMerchants }) => {
  const dispatch = useDispatch();
  const [profileType, setprofileType] = useState('agents')
  const [merchantsState, setmerchantsState] = useState({
    totalDocs: 2189,
    pageSize: 15,
    totalPages: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 1,
    nextPage: 1
  })
  const [dataEx, setDataEx] = useState([]);
  const [merchantsData, setmerchantsData] = useState([])
  const [page, setpage] = useState(1)
  const [limit, setlimit] = useState(30)
  const [openModal, setopenModal] = useState(false)
  const [exciteAgents, setexciteAgents] = useState([])
  const [merchantExportData, setmerchantExportData] = useState([])


  useLayoutEffect(() => {
    getAllMerchants()
  }, [])

  useEffect(() => {
    setDataEx(exportData);
  }, [exportData]);

  useMemo(() => getAllMerchants(), [page])

  async function getAllMerchants() {
    try {
      dispatch(loadStart());
      if (page) {
        const headers = {
          'Authorization': `Bearer ${admin_token}`,
          'Accept': 'application/json'
        }
        const response = await fetch(`${host}/auth/merchants/${page}/${limit}`, { headers })
        // dispatch(getMerchants(page, limit, headers));
        const result = await response.json()
        const { success, message, merchants } = result
        if (result && success === true) {
          const { docs, totalDocs, totalPages, hasPrevPage, hasNextPage, prevPage, nextPage } = merchants
          setmerchantsData(docs)
          setmerchantsState({
            ...merchantsState, totalDocs: totalDocs, totalPages: totalPages, hasPrevPage: hasPrevPage, hasNextPage: hasNextPage, prevPage: prevPage, nextPage: nextPage,
          })
          dispatch({
            type: "MERCH_ALL",
            payload: { merchants: docs, message: message, success: success },
          });
          merchantCsv()
          const agents = await getAgents()
          agents.filter((agent) => {
            agent.sales = getTotalSusbscriptionOfEachAgent(docs, agent.agentCode)
          })
          setexciteAgents(agents)
          console.log('agents',agents)
          agents.length >= 0 && dispatch(loadStop());
        }
        else {
          dispatch(loadStop());
          Flash("warning", message || 'Something went wrong', "", 3000);
        }
      }
      else {
        dispatch(loadStop());
      }
    } catch (error) {
      dispatch(loadStop());
      Flash("error", error.message, "", 3000);
    }
  }


  //Filter
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = exciteAgents?.filter(
    (item) =>
      item.fullName &&
      item.fullName.toLowerCase().includes(filterText.toLowerCase())
  );

  const filteredMerchantsItems = merchantsData?.filter(
    (item) =>
      item.fullname &&
      item.fullname.toLowerCase().includes(filterText.toLowerCase())
  );

  const changeProfileType = (type) => {
    setprofileType(type)
  }

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <TextField
          id="search"
          type="text"
          aria-label="Search Input"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          label="Search by name"
        />
        <Button type="button" onClick={handleClear}>
          X
        </Button>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  async function getAgents() {
    try {
      const response = await useAxios.get("/excite/agent/get-all");
      const { code, success, agents, feedbacks, message } = response.data
      if (response.data && success === true) {
        dispatch({
          type: "SET_ALL",
          payload: { agents: agents, feedbacks: feedbacks },
        });
        dispatch(loadStop());
        return agents
      } else {
        dispatch({
          type: "SET_ALL",
          payload: { agents: [], feedbacks: [] },
        });
        Flash("warning", message, "", 3000);
        return []
      }
    } catch (error) {
      dispatch(loadStop());
      dispatch({
        type: "SET_ALL",
        payload: { agents: [], feedbacks: [] },
      });
      Flash("error", error.message, "", 3000);
    }
  }

  function getTotalSusbscriptionOfEachAgent(merchantsData, referral) {
    let merchantSubscriptionTotal = 0
    merchantsData.map(merchant => {
      if (merchant.referral.refCode === referral) {
        merchantSubscriptionTotal += parseInt(merchant.payment[0]?.price || 0)
      }
    })
    return merchantSubscriptionTotal
  }

  const agentscolumns = React.useMemo(
    () => [
      {
        name: "Fullname",
        sortable: true,
        cell: (row) => {
          return <div>{row.fullName?.toUpperCase()}</div>;
        },
      },
      {
        name: "Phone Number",
        selector: "mobile",
        sortable: true,
      },
      {
        name: "Agent ID",
        cell: (row) => {
          if (row.approve) {
            return <div>{row?.agentCode}</div>;
          } else {
            return <div>Pending Approval</div>;
          }
        },
      },
      {
        name: "Referrals",
        cell: (row) => {
          return <div>{row?.merchants?.length}</div>;
        },
      },
      {
        name: "Sales",
        cell: (row) => {
          // return <div>₦{row?.totalSub.toLocaleString()}</div>;
          return <div>₦{row.sales.toLocaleString()}</div>;
        },
      },
      {
        name: "Action",
        cell: (row) => {
          return (
            <Link to={`/admin?agent=${row._id}`}>
              <Button>View</Button>
            </Link>
          );
        },
      },
    ],
    []
  );


  function merchantCsv() {
    const newCSVFile = []
    merchantsData?.filter((item, index) => {
      item["NAME"] = item.fullname;
      item["PHONE NUMBER"] = item.storeInfo?.storePhone || 0;
      item["EMAIL"] = item.email;
      item["STORE ADDRESS"] = item?.storeInfo?.storeName;
      const { fullname, storeInfo, email } = item;
      return newCSVFile.push({
        "ID": index += 1,
        "NAME": fullname,
        "PHONE NUMBER": item["PHONE NUMBER"],
        "EMAIL": email,
        "STORE ADDRESS": item["STORE ADDRESS"],
        "DATE REGISTERED": new Date(item.createdAt).toDateString(),
        "SUBSCRIPTION":  formatDate(item?.subscriptionEnd)
      })
    });
    setmerchantExportData(newCSVFile)
    return newCSVFile
  }

  const formatDate = (date) => {
    if (date === 0) {
      return 'No Plan'
    }
    else {
      const newDate = new Date(date);
      let month = newDate.getMonth() + 1;
      let day = newDate.getDate();
      month = month < 10 ? `0${month}` : month
      day = day < 10 ? `0${day}` : day
      const formatedDate = `${newDate.getFullYear()}-${month}-${day}`
      return formatedDate
    }
  }

  const merchantscolumns = React.useMemo(
    () => [
      {
        name: "Fullname",
        selector: "fullname",
        sortable: true,
        cell: (row) => {
          return <div>{row.fullname?.toUpperCase()}</div>;
        },
      },
      {
        name: "Phone Number",
        selector: "phone",
        sortable: true,
        cell: (row) => {
          return <div>{row.phone ? row.phone : row.storeInfo.storePhone}</div>;
        },
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
        cell: (row) => {
          return <div>{row.email}</div>;
        },
      },
      {
        name: "Registered",
        selector: "createdAt",
        sortable: true,
        cell: (row) => {
          return <div>{new Date(row.createdAt).toDateString()}</div>;
        },
      },
      {
        name: "Subscription End",
        selector: "subscriptionEnd",
        sortable: true,
        cell: (row) => {
          return <div>{formatDate(row?.subscriptionEnd)}</div>;
        },
      },
      {
        name: "Action",
        cell: (row) => {
          return (
            <Link to={`/admin?merchant=${row._id}`}>
              <Button>Add Subscription</Button>
            </Link>
          );
        },
      },
    ],
    []
  );


  const today = new Date().toLocaleDateString();
  const fileName = `All Agents Updates: ${today}`;
  const merchantFileName = `merchant${today}`;

  return (
    <>
      {openModal && <SubscriptionModal />}
      <div className="some">
        <SubscriptionModal />
        <div className="profileType">
          <div>
            <button className="active" onClick={() => changeProfileType('agents')}>Agents</button>
          </div>
          <div>
            <button className="unactive" onClick={() => changeProfileType('merchants')}>Merchants</button>
          </div>
        </div>
        {profileType === 'agents' ? <>
          {/* <Paper className="mb-4">
            <div className="p-2">
              {dataEx.length > 0 ? (
                <ExportCSV csvData={dataEx} fileName={fileName}></ExportCSV>
              ) : null}
            </div>
          </Paper> */}

          <Card>
            <DataTable
              title="Sales Agents"
              columns={agentscolumns}
              data={filteredItems}
              //   defaultSortField="title"
              sortable
              search
              responsive
              // onRowClicked={(row)=>handleRowClick(row)}
              highlightOnHover
              striped
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
            />
          </Card>
        </> : <>
          <SubscriptionModal />
          <Paper className="mb-4">
            <div className="p-2">
              {merchantsData.length > 0 ? (
                <ExportCSV csvData={merchantExportData} fileName={merchantFileName}></ExportCSV>
              ) : null}
            </div>
          </Paper>
          <Card>
            <DataTable
              title="Merchants"
              columns={merchantscolumns}
              data={filteredMerchantsItems}
              //   defaultSortField="title"
              sortable
              search
              responsive
              // onRowClicked={(row)=>handleRowClick(row)}
              highlightOnHover
              striped
              // pagination
              // paginationResetDefaultPage={resetPaginationToggle}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
            />
            <div className="icon-div">
              {merchantsState.totalDocs && <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>{`Total Merchant ${merchantsState.totalDocs}`}</div>}
              {merchantsState.hasPrevPage && <div className="arrow-div" onClick={() => setpage(1)}>
                <img src={Prev} className="arrow-image" />
              </div>}
              {merchantsState.hasPrevPage === true && <div className="arrow-div" onClick={() => setpage(page - 1)}>
                <img src={Left} className="arrow-image" />
              </div>}
              {merchantsState.hasNextPage === true && <div className="arrow-div" onClick={() => setpage(page + 1)}>
                <img src={Right} className="arrow-image" />
              </div>}
              {merchantsState.hasNextPage && <div className="arrow-div" onClick={() => setpage(merchantsState.totalPages)}>
                <img src={Next} className="arrow-image" />
              </div>}
            </div>
          </Card>
        </>}
      </div>
      {/* <AssignModal open={open} data={modalData} handleModal={handleModal}/> */}
    </>
  );
};

const mapStateToProps = (state) => {
  let dtEx = state.profile?.agents;
  const dataEx = () =>
    dtEx?.map((item, index) => {
      item["Total Referrals"] = item.merchants?.length;
      item["Total Sales"] = handleTotalSales(item);
      item["Supervisor"] = handleGetSupervision(item.supervisedBy, dtEx);
      const { fullName, Supervisor, agentCode } = item;
      return {
        "ID": index += 1,
        NAME: fullName,
        "REF CODE": agentCode,
        SUPERVISOR: Supervisor,
        "TOTAL REFERRALS": item["Total Referrals"],
        "TOTAL SALES": item["Total Sales"],
      };
    });
  return {
    agents: state.profile?.agents,
    merchants: state.profile?.merchants || [],
    admin_token: state.auth?.token,
    exportData: dataEx(),
  };
};
export default connect(mapStateToProps)(Table);
