import { Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
export default function Index() {
  return (
    <div className={styles.root}>
      <div className="container">
        <div className={styles.wrapper}>
          <h1>
            <span>Excite Enterprise</span> Sales Agent Management System
          </h1>
          <p className="mb-4">
            Excite Enterprise comes with very flexible sales structure and offer
            agents the opportunity to earn commission based on their value of
            the sales.
          </p>
          <div className={styles["btn-div"]}>
            <Link to="/accounts?q=sign-up" className={styles.btn}>
              <Button className="mb-4 mr-2">Register</Button>
            </Link>
            <Link to="/accounts?q=login" className={styles.btn}>
              <Button className="mb-4">Login</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
