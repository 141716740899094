// import {getDecodedToken, getValidToken} from "../../utility/axios-token-manager/token"

const initialState = {
  userProfile: null,
  agentMerchant: [],
  agents: [],
  merchants: [],
  feedbacks: [],
  merchant: {},
  makeFeedBack: false,
  success: false,
  message: '',

}
const setProfile = (state, data) => {
  return {
    ...state, userProfile: data.profile, agentMerchant: data.merchants
  }
};
const setAll = (state, data) => {
  return {
    ...state, agents: data.agents, feedbacks: data.feedbacks
  }
};

const merchantsAll = (state, data) => {
  return {
    ...state, merchants: data.merchants, message: data.message, success: data.success
  }
};

const merchant = (state, data) => {
  return {
    ...state, merchant: data.merchant
  }
};



const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PROFILE":
      return setProfile(state, action.payload);
    case "SET_ALL":
      return setAll(state, action.payload);
    case "MERCH_ALL":
      return merchantsAll(state, action.payload);
    case "MERCHANT":
      return merchant(state, action.payload);
    case "SET_UPDATE":
      return { ...state, agents: action.payload };
    case "MAKE_FEEDBACK":
      return { ...state, makeFeedBack: action.payload };
    default:
      return state;
  }
};

export default authReducer;