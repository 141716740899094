import api, { setToken } from './init'
import { getDecodedToken } from './token'

// Sends a POST request to /auth/sign-up on the server, with first name, last name, email & password registering the user and returning the JWT
export async function signUp(inputs) {
  try {
    const response = await api.post('/auth/signup', { ...inputs });
    const token = response.data.token;
    if (token) {
      setToken(token)
      return getDecodedToken()
    } else {
      return null
    }
  } catch (error) {
    return null;
  }

}
export async function signUpAdmin(inputs) {
  try {
    const response = await api.post('/auth/management/set-up', { ...inputs });
     
    if (response.data.code === 201) {
      return true
    } else {
      return null
    }
  } catch (error) {
    return null;
  }

}
export async function signUpExtAdmin(inputs) {
  try {
    const response = await api.post('/auth/ext-sales/set-up', { ...inputs });
    if (response.data.code === 201) {
      return true
    } else {
      return null
    }
  } catch (error) {
    return error.message;
  }

}

// Sends a POST request to /auth on the server, with the email & password returning the JWT
// Belonging to the user with supplied credentials
export async function signIn({ email, password, lat, long, address }) {
  const data = { email: email, password: password, lat: lat, long: long, location: address }
  return api.post('/auth/login/agent', data)
    .then(res => {
      const { token, message, success } = res.data
      if (success === true) {
        setToken(token)
        return {
          user: getDecodedToken(),
          token: token,
          success: success,
          message: message
        }
      }
      else {
        return {
          user: {},
          token: '',
          success: false,
          message: message
        }
      }
    })
    .catch(error => {
      return {
        user: {},
        token: '',
        success: false,
        message: error.message
      }
    })
}
export async function signInAdmin({ email, password }) {
  const data = { email: email, password: password }
  return api.post('/auth/login/admin', data)
    .then(res => {
      const { success, message, token } = res.data
      if (success === true) {
        setToken(token)
        return {
          token: getDecodedToken(),
          message: message,
          success: success,
          data: res.data.data
        }
      }
      else {
        return {
          token: '',
          message: message,
          success: success
        }
      }
    })
    .catch(error => {
      return {
        token: getDecodedToken(),
        message: error.message
      }
    })
}
export async function signInExtAdmin({ email, password }) {
  const data = { email: email, password: password }
  return api.post('/auth/login/ext-sales/admin', data)
    .then(res => {
      const token = res.data.token
      setToken(token)
      return getDecodedToken()
    })
    .catch(res => {
      if (res.status === 400 || res.status === 401) {
        // alert("There was an error with your email or password. Please try again.")
        return null
      }
    })
}
