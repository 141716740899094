import React from "react";
import Layout from "../layout/adminExt/Layout";
import Table from "./tables/ReactDataTable";
import { useQueryParam } from "use-query-param";
import Profile from "./agent/Profile";
export default function Index() {
  const { queryParams } = useQueryParam(window.location.href);
  if(!queryParams.agent) return (
    <Layout>
      <Table />
    </Layout>
  );
    return(
        <Layout>
            <Profile id={queryParams.agent}/>
        </Layout>
    )
}
