import React from "react";
import Statistic from './subs/statistics/Index'
import Sales from './subs/sales/Index'
import SetUp from './subs/setup/Index'
import { useQueryParam } from "use-query-param";

//
export default function Index() {
  const { queryParams } = useQueryParam(window.location.href);
  

  switch (queryParams.q) {
    case "sales":
        return <Sales />;
    case "setup":
        return <SetUp />;
    default:
        return  <Statistic />
}
}
