import React from "react";
import { connect, useDispatch } from "react-redux";
import { makeFeedBack } from "../../../../redux/actions/profile";

import { Button, MenuItem, Modal, Slide, TextField } from "@material-ui/core";
import styles from "./style.module.css";
import sectors from "../../../Restricted/sectors";
import useAxios from "../../../../utility/axios-token-manager/init";
import { loadStart, loadStop } from "../../../../redux/actions/loading";
import Flash from "../../../../utility/Flash";


export const Feedback = ({ userID, feedback,userSector }) => {
  const regions = sectors[userSector]?.regions;
  const dispatch = useDispatch();
const [inputs,setInputs] = React.useState({location:"",report:"",reporter:userID,sector:userSector})


React.useMemo(() => setInputs({reporter:userID,sector:userSector}), [userID,userSector]);

const handleChange=(e)=>{
    inputs[e.target.name] = e.target.value;
    setInputs({...inputs})
}
  const handleSubmit =async ()=>{
      if(!inputs.report.trim()) return Flash("error","Please enter a report","",3000)
      dispatch(loadStart())
    try {
        dispatch(loadStop())
        const response =await useAxios.post('/sales-agent/my-feedback',{...inputs});
        if(response.data.code===201){
            Flash('success',"Feedback submitted","",3000);
            setInputs({location:"",report:"",reporter:userID,sector:userSector})
            dispatch(makeFeedBack(false))
        }else{
            Flash('error',"Something went wrong","",3000);
        }
    } catch (error) {
        Flash('error',"Server Error","",3000);
    }
  
  }

  return (
    <Modal open={feedback} onClose={() => console.log("closed")}>
      <Slide direction="down" in={feedback}>
        <div className={styles["modal"]}>
          <div className={styles["form"]}>
            <div
              className={styles["x-btn"]}
              onClick={() => dispatch(makeFeedBack(false))}
            >
              <Button color="secondary">
                <i className=" btn bi bi-x-circle-fill"></i>
              </Button>
            </div>
            <div className="mb-4">
              <TextField fullWidth label="Select Location" name="location" select value={inputs.location} onChange={handleChange}>
                  {regions?.map((item,index)=>
                  <MenuItem key={index} value={item}>{item}</MenuItem>)}
              </TextField>
            </div>
            <div>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                rows={4}
                label="Type your report"
                name="report"
                value={inputs.report}
                onChange={handleChange}
              />
            </div>
            <div className={styles['submit-btn']} >
                <Button onClick={handleSubmit} fullWidth disabled={!inputs.report?.trim() || !userID || !userSector}>Submit</Button>
            </div>
          </div>
        </div>
      </Slide>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  userID: state.profile?.userProfile?._id,
  userSector: state.profile?.userProfile?.sector,
  feedback: state?.profile?.makeFeedBack,
});

export default connect(mapStateToProps, null)(Feedback);
