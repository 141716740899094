import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
// import Button from '../shared/Button';
import {Card} from "@material-ui/core";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";


const Table = ({ agent }) => {

  const [data,setData]= useState([]);
  useEffect(()=>{
    setData(agent?.merchants)
  },[agent])
  const columns = React.useMemo(
    () => [
      {
        name: "Name",
        sortable: true,
        cell: (row) => {
          return <div>{row.fullname}</div>;
        },
      },
      {
        name: "Phone Number",
        sortable: true,
        cell: (row) => {
          return <div>{row.storeInfo?.storePhone}</div>;
        },
      },
      {
        name: "Email",
        cell: (row) => {
          return <div>{row.email}</div>;
        },
        sortable: true,
      },
    ],
    []
  );

  return (
    <>
      <div className="some">
        <Card>
          <DataTable
            title={<Typography variant="h6" className="mt-4 mb-4">Referrals</Typography>}
            columns={columns}
            data={data}
            //   defaultSortField="title"
            sortable
            search
            responsive
            // onRowClicked={(row)=>handleRowClick(row)}
            highlightOnHover
            striped
            pagination
          />
        </Card>
      </div>
      {/* <AssignModal open={open} data={modalData} handleModal={handleModal}/> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    agents: state.profile.agents,
  };
};
export default connect(mapStateToProps)(Table);
