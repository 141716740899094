import React from 'react'
// import Register from './Setup'
import Login from './Login'
import { useQueryParam } from "use-query-param";


 
export default function Accounts() {

    const { queryParams } = useQueryParam(window.location.href);
    switch (queryParams.q) {
        // case "set":
        //     return <Register />;
        case "login":
            return <Login />;
        default:
            return <Login />;
    }
}
