import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
// import Button from '../shared/Button';
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";


const Table = ({ merchant }) => {


  const [data, setData] = useState([])
  useEffect(() => {
    const filteredMerchant = merchant.map(item => item.payment.length > 0 && item)
    setData(merchant || [])
  }, [merchant])
  const columns = React.useMemo(
    () => [
      {
        name: "Email",
        cell: (row) => {
          row.payment.length > 0 && <div>{row.email}</div>;
        },
        sortable: true,
      },
      {
        name: "Package",
        sortable: true,
        cell: (row) => {
          row.payment.length > 0 && <div>{row.payment[0]?.plan || 'No Plan'}</div>;
        },
      },
      {
        name: "Billing",
        sortable: true,
        cell: (row) => {
          row.payment.length > 0 && <div>{row.payment[0]?.cycle || 0} month(s)</div>;
        },
      },
      {
        name: <div>Amount <span>( &#8358; )</span></div>,
        cell: (row) => {
          row.payment.length > 0 && <div>₦{(parseInt(row.payment[0]?.price || 0)).toLocaleString()}</div>;
        },
        sortable: true,
      },
      // {
      //   name: "Action",
      //   cell: (row) => {
      //       return <Link to={`/admin?agent=${row._id}`}><Button>View</Button></Link>;
      //     }
      //   },
    ],
    []
  );

  return (
    <>
      <div className="table-wrapper">
        <Typography variant="h6">Sales</Typography>
        <DataTable
          title=""
          columns={columns}
          data={data}
          //   defaultSortField="title"
          sortable
          search
          responsive
          // onRowClicked={(row)=>handleRowClick(row)}
          highlightOnHover
          striped
          pagination

        />

      </div>
      {/* <AssignModal open={open} data={modalData} handleModal={handleModal}/> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    earnings: state.profile?.userProfile?.earnings,
    merchant: state.profile.agentMerchant,
  };
};
export default connect(mapStateToProps)(Table);
