import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
// import Button from '../shared/Button';
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";


const Table = ({ merchants }) => {

  const [data, setData] = useState([]);
  useEffect(() => {
    setData(merchants ? merchants : [])
  }, [merchants])
  const columns = React.useMemo(
    () => [
      {
        name: "Name",
        sortable: true,
        cell: (row) => {
          return <div>{row.fullname}</div>;
        },
      },
      {
        name: "Phone Number",
        sortable: true,
        cell: (row) => {
          return <div>{row.storeInfo?.storePhone}</div>;
        },
      },
      {
        name: "Email",
        cell: (row) => {
          return <div>{row.email}</div>;
        },
        sortable: true,
      },
    ],
    []
  );

  return (
    <>
      <div className="table-wrapper">
        <Typography variant="h6">Referrals</Typography>
        <DataTable
          title=""
          columns={columns}
          data={data}
          //   defaultSortField="title"
          sortable
          search
          responsive
          // onRowClicked={(row)=>handleRowClick(row)}
          highlightOnHover
          striped
          pagination

        />

      </div>
      {/* <AssignModal open={open} data={modalData} handleModal={handleModal}/> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    merchants: state.profile?.userProfile?.merchants,
  };
};
export default connect(mapStateToProps)(Table);
