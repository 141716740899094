import React from "react";
import  Feedback  from "./Feedback";
import Layout from "../../layout/admin/Layout";
import { Typography, Paper } from "@material-ui/core";

// import { useQueryParam } from "use-query-param";

export default function Index() {
  // const { queryParams } = useQueryParam(window.location.href);
 
    return(
        <Layout>
            <div style={{maxHeight:'650px',overflow:'auto'}}>
              <Paper style={{padding:'20px 10px',marginBottom:'10px'}} elevation={5}>
                <Typography variant='h5'>Agents Feedbacks</Typography>
              </Paper>
              <Feedback />
            </div>
        </Layout>
    )
}
