import React from "react";
import Layout from "../../layout/admin/Layout";
import Table from "./tables/ReactDataTable";
import { useQueryParam } from "use-query-param";
export default function Sectors() {

  const { queryParams } = useQueryParam(window.location.href);
  if(queryParams.sector) return (
    <Layout>
      <Table sector={queryParams.sector}/>
    </Layout>
  );
    return(
        <Layout>
            <Table sector={[]}/>
        </Layout>
    )
}
