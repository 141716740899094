import React, { useState } from "react";
import { connect } from "react-redux";
import styles from "./login.module.css";
import { signInAdmin } from "./../../../utility/axios-token-manager/auth";
import Flash from "./../../../utility/Flash";
import { loadStart, loadStop } from "./../../../redux/actions/loading";
import { signInSuccess } from './../../../redux/actions/auth'
import { useDispatch } from "react-redux";
import logo from './../../../components/asset/img/Excite WLL.png'
//

const redirectToMyDashboard = (user) => {
  switch (user) {
    case "EXMANAF":
      return window.location.assign("/admin");
    default:
      break;
  }
};

//
const Login = (props) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [loginInputs, setLoginputs] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    loginInputs[e.target.name] = e.target.value;
    setLoginputs({ ...loginInputs });
  };

  const handleSubmit = async () => {
    const email = loginInputs.email.trim().toLowerCase();
    const password = loginInputs.password;
    if (!email || !password) return Flash('error', 'Missing login details', '', 3000)
    try {
      dispatch(loadStart());
      const user = await signInAdmin({ email: email, password });
      const { success, message, token, data } = user
      if (success === true) {
        signInSuccess(token)
        dispatch(loadStop());
        Flash("success", message, "", 5000);
        return redirectToMyDashboard(data.userType);
      }
      else {
        Flash("warning", message, "", 5000);
        dispatch(loadStop());
      }
    } catch (error) {
      dispatch(loadStop());
      Flash("error", error.message, "", 5000);
    }
  };
  return (
    <section className={styles.section}>
      <div className={"container " + styles.container}>
        <div className={styles.action}>
          <div className={styles.logoWrapper}>
            <a href="/">
              <img src={logo} alt='logo' />
            </a>
            <div>Admin Login to your account</div>
          </div>
          <div className={styles.form}>
            <div className={styles.inputWrapper}>
              <i className="fa fa-envelope"></i>
              <input
                onChange={(e) => handleChange(e)}
                type="text"
                placeholder="Email"
                name="email"
                value={loginInputs.email}
              />
            </div>
            <div className={styles.inputWrapper + " mt-4"}>
              <i className="fa fa-key"></i>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="password"
                name="password"
                value={loginInputs.password}
                onChange={(e) => handleChange(e)}
              />
              <span onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? "Hide" : "Show"}
              </span>
            </div>
          </div>
          <div className={styles.cta}>
            <button onClick={handleSubmit}>Login</button>
          </div>
          <p className={"text-center  mt-2 " + styles.forgot}>
            {/* Forgot password ? click <a href="/forgot-password">here</a> */}
          </p>
          <div className={styles.create}>
            <p className={"text-center"}>
              {/* Don't have an account ? <a href="/admin/accounts?q=set">Set Up</a> */}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    isAuth: state.auth.token !== null,
    is_seller: state.auth.is_seller,
    email: state.auth.email,
    loading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps)(Login);
