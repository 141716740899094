import React, { useState } from "react";
import { connect } from "react-redux";
import styles from "./login.module.css";
import Flash from "../../../utility/Flash";
import { loadStart, loadStop } from "../../../redux/actions/loading";
// import {authLogin} from './../../../redux/actions/auth'
import { useDispatch } from "react-redux";
import logo from "./../../../components/asset/img/Excite WLL.png";
import { useQueryParam } from "use-query-param";
import useAxios from "../../../utility/axios-token-manager/init";



//
const Login = (props) => {

  const { queryParams } = useQueryParam(window.location.href);
  const { email, token } = queryParams;

  if (!email || !token) Flash('error', 'no email or token', '', 3000)
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [loginInputs, setLoginputs] = useState({
    password2: "",
    password: "",
  });

  const handleChange = (e) => {
    loginInputs[e.target.name] = e.target.value;
    setLoginputs({ ...loginInputs });
  };

  const handleSubmit = async () => {

    try {
      dispatch(loadStart());
      const response = await useAxios.post(`/sales-auth/confirm-reset/?email=${email}&token=${token}`, { password: loginInputs.password, password2: loginInputs.password2 });
      // 
      dispatch(loadStop());
      const { success, message } = response.data
      if (success === true) {
        Flash('success', message, '', 3000);
        return window.location.href = '/console'
      }
      else {
        Flash('warning', message, '', 3000);
      }

    } catch (error) {
      return Flash("error", "Network/Server error", "", 5000);
    }
  };
  return (
    <section className={styles.section}>
      <div className={"container " + styles.container}>
        <div className={styles.action}>
          <div className={styles.logoWrapper}>
            <a href="/">
              <img src={logo} alt="logo" />
            </a>
            <div>Login to your account</div>
          </div>
          <div className={styles.form}>
            <div className={styles.inputWrapper + " mt-4"}>
              <i className="fa fa-key"></i>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="New password"
                name="password"
                value={loginInputs.password}
                onChange={(e) => handleChange(e)}
              />
              <span onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? "Hide" : "Show"}
              </span>
            </div>
            <div className={styles.inputWrapper + " mt-4"}>
              <i className="fa fa-key"></i>
              <input
                type={showPassword2 ? "text" : "password"}
                placeholder="Confirm password"
                name="password2"
                value={loginInputs.password2}
                onChange={(e) => handleChange(e)}
              />
              <span onClick={() => setShowPassword2(!showPassword2)}>
                {showPassword2 ? "Hide" : "Show"}
              </span>
            </div>
          </div>
          <div className={styles.cta}>
            <button onClick={handleSubmit}>Reset Password</button>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    isAuth: state.auth.token !== null,
    is_seller: state.auth.is_seller,
    loading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps)(Login);
