const sectors = {
    1: {
        name: "Sector One",
        regions: ["Yaba", "Lagos Island", "Aja"],
        alias: "Yaba – Lagos Island – Aja",
        code: 1
    },
    2: {
        name: "Sector Two",
        regions: ["Surulere", "Alaba", "Badagry"],
        alias: "Surulere – Alaba- Badagry",
        code: 2
    },
    3: {
        name: "Sector Three",
        regions: ["Oshodi", "Ikeja", "Ikorodu"],
        alias: "Oshodi - Ikeja - Ikorodu",
        code: 3
    },
    4: {
        name: "External Agent",
        regions: ["State the location in description"],
        alias: "External",
        code: 4
    },
    5: {
        name: "Online Agent",
        regions: ["State the location in description"],
        alias: "Online",
        code: 5
    },
}

const convertToThousand = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
}

export const subscriptionCycle = [
    {
        id: "1",
        name: 'Monthly',
        duration: 1,
    },
    {
        id: "2",
        name: '3 Months',
        duration: 3,
    },
    {
        id: "3",
        name: '6 Months',
        duration: 6,
    },
    {
        id: "4",
        name: 'Yearly',
        duration: 12,
    }
]
// const updatePrice = (cycle, price) => {
//     if (cycle === 'Silver' && paymentCycle === 12) {
//         return convertToThousand(parseInt(10) * parseInt(price))
//     }
//     else {
//         return convertToThousand(parseInt(paymentCycle) * parseInt(price))
//     }
// }
export const subscriptionPlan = [
    // {
    //     id: "1",
    //     name: "Bronze",
    //     // price: 1000,
    //     // price: updatePrice('Bronze', 1000),
    //     badge: false,
    //     offerings: [
    //         {
    //             id: "1",
    //             offer: "Unlimited Product listing",
    //             status: true
    //         },
    //         {
    //             id: "2",
    //             offer: "Invoice",
    //             status: false
    //         },
    //         {
    //             id: "3",
    //             offer: "Book Keeping",
    //             status: false
    //         },
    //     ]
    // },
    {
        id: "2",
        name: "Silver",
        // price: updatePrice('Silver', 5000),
        badge: true,
        offerings: [
            {
                id: "1",
                offer: "Unlimited Product listing",
                status: true
            },
            {
                id: "2",
                offer: "Invoice",
                status: true
            },
            {
                id: "3",
                offer: "Book Keeping",
                status: true
            },
        ]
    },
    // {
    //     id: "3",
    //     name: "Gold",
    //     price: updatePrice(10000),
    //     badge: false,
    //     offerings: [
    //         {
    //             id: "1",
    //             offer: "Unlimited Product listing",
    //             status: true
    //         },
    //         {
    //             id: "2",
    //             offer: "Invoice",
    //             status: true
    //         },
    //         {
    //             id: "3",
    //             offer: "Book Keeping",
    //             status: true
    //         },
    //         {
    //             id: "4",
    //             offer: "Merchant Verification",
    //             status: false
    //         },
    //         {
    //             id: "5",
    //             offer: "Social Commerce",
    //             status: false
    //         },
    //     ]
    // },
]

export const sections = Object.keys(sectors);


export default sectors