import {
  Paper,
  Typography,
  Avatar,
  Tooltip,
  Button,
  MenuItem,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styles from "./../styles.module.css";
import { ImageResizer } from "../../../../ImageResizer";
import useAxios from "../../../../../utility/axios-token-manager/init";
import Flash from "../../../../../utility/Flash";
import { loadStart, loadStop } from "./../../../../../redux/actions/loading";
import { getUserProfile } from "./../../../../../redux/actions/profile";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { frontHost } from "./../../../../../frontHost";
// import ImageZoom from "react-medium-image-zoom";
import QRCode from "react-qr-code";
import { WhatsappShareButton, FacebookShareButton, FacebookIcon, WhatsappIcon, TelegramShareButton, TelegramIcon } from 'react-share'

function Index({ profile }) {

  const dispatch = useDispatch();

  //Image Uploading Section

  //
  const uploadToS3 = async (file, field) => {
    let img = file;
    if (file.size > 300000) {
      img = await ImageResizer(file);
    }
    try {
      const resizeImg = await ImageResizer(img);
      const image = new FormData();
      image.append("image", resizeImg);
      dispatch(loadStart());
      useAxios
        .post("/upload/affiliates", image, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          dispatch(loadStop());
          if (res.data.code === 201) {

            handleUpdateUpload(field, res.data.data);
          }
        })
        .catch((e) => {
          dispatch(loadStop());
          Flash("error", "An error occured", "Error", 5000);
        });
    } catch (error) {
      Flash("error", "An error occured", "Error", 5000);
    }
  };

  const handleUpdateUpload = async (field, data) => {
    try {
      const response = await useAxios.put("/sales-agent/my-upload", {
        field,
        data,
      });
      dispatch(getUserProfile());
    } catch (error) { }
  };

  const handleUpload = async (e, field) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      if (!file.type.split("/").includes("image"))
        return Flash("error", "Image format should be jpg/png", "", 3000);
      await uploadToS3(file, field);
    } else {
      return Flash("error", "Abort", "", 3000);
    }
  };

  const [edit, setEdit] = useState(false);

  const [account, setAccount] = useState({
    fullName: "",
    address: "",
    mobile: "",
  });

  useEffect(() => {
    if (profile) {
      setAccount({ ...profile });
    }
  }, [profile]);

  const handleChange = (e) => {
    account[e.target.name] = e.target.value;
    setAccount({ ...account });
  };

  const updateBio = async () => {
    try {
      const response = await useAxios.put("/sales-agent/my-profile", {
        ...profile,
        ...account,
      });
     
      if (response.data.code === 201) {
        window.location.reload();
      } else {
        return Flash("error", "Failed to update profile", "", 3000);
      }
    } catch (error) {
      return Flash("error", "Server error", "", 3000);
    }
  };
  return (
    <>
      <Paper className={styles["paper"]}>
        <Typography className="mb-4" variant="h6">
          Application
        </Typography>
        <table className="table">
          <tbody>
            <tr>
              <td>Registration Status</td>
              <td>{profile?.approve ? "Approved" : "Pending"}</td>
            </tr>
            <tr>
              <td>Agent Code</td>
              <td>{profile?.approve ? profile?.agentCode : "Pending"}</td>
            </tr>
            {/* <tr>
              <td>Referral Link</td>
              <td>{profile?.agentCode}</td>
            </tr> */}
          </tbody>
          <tfoot className="pt-4">
            <Typography variant="h6" className="mt-4 mb-2">
              Find your referral link and code below
            </Typography>
            <tr>
              <td>
                <MenuItem className="d-none d-lg-inline">
                  {profile?.approve
                    ? `Website: ${frontHost}/services/agent/referrals/registration/${profile?.agentCode}`
                    : "Awaiting approval"}
                </MenuItem>
                <CopyToClipboard
                  text={`${frontHost}/services/agent/referrals/registration/${profile?.agentCode}`}
                  onCopy={() => Flash("success", "Copied", "", 3000)}
                >
                  <Button disabled={!profile?.approve}>
                    {profile?.agentCode ? "Copy For Web " : "Pending"}
                  </Button>
                </CopyToClipboard>
              </td>
            </tr>
            <tr>
              <td>
                <MenuItem className="d-none d-lg-inline">
                  {profile?.approve
                    ? `Application User: ${profile?.agentCode}`
                    : "Awaiting approval"}
                </MenuItem>
                <CopyToClipboard
                  text={`${profile?.agentCode}`}
                  onCopy={() => Flash("success", `Copied ${profile?.agentCode}`, "", 3000)}
                >
                  <Button disabled={!profile?.approve}>
                    {profile?.agentCode ? "Copy For App" : "Pending"}
                  </Button>
                </CopyToClipboard>
              </td>
            </tr>
            <tr>
              <td>
                {profile?.approve && <QRCode value={`${frontHost}/services/agent/referrals/registration/${profile?.agentCode}`} />}
              </td>
            </tr>
            <tr>
              <td>
                {profile?.approve && <div> <h6>Share on Social Media</h6>
                  <WhatsappShareButton url={`${frontHost}/services/agent/referrals/registration/${profile?.agentCode}`} title="Whatsapp">
                    <WhatsappIcon />
                  </WhatsappShareButton>
                  <FacebookShareButton url={`${frontHost}/services/agent/referrals/registration/${profile?.agentCode}`} quote="Join Excite Enterprise" >
                    <FacebookIcon />
                  </FacebookShareButton>
                  <TelegramShareButton url={`${frontHost}/services/agent/referrals/registration/${profile?.agentCode}`} title="Facebook">
                    <TelegramIcon />
                  </TelegramShareButton> </div>}
              </td>
            </tr>
          </tfoot>
        </table>
      </Paper>
      <Paper className={styles["paper"]}>
        <Typography className="mb-4" variant="h6">
          Bio Data
        </Typography>
        <div className="d-flex justify-content-end">
          <div className="d-flex">
            <div style={{ background: "#a7cc48" }} className={!edit ? 'd-none' : "show"}>
              <Button onClick={updateBio} className='r-2'>Save</Button>
            </div>
            <Button className="mr-4" onClick={() => setEdit(!edit)}>
              <i
                className="fa fa-edit"
                style={{ fontSize: "24px", color: "#888", cursor: "pointer" }}
              ></i>
            </Button>
          </div>
        </div>
        <table className="table">
          <tbody>
            <tr>
              <td>Full Name</td>
              <td>
                <input
                  onChange={handleChange}
                  maxLength={100}
                  style={{ outline: "none" }}
                  name="fullName"
                  className={!edit ? "w-100 border-0" : "w-100"}
                  autoFocus={!edit}
                  readOnly={!edit}
                  value={account.fullName}
                />
              </td>
            </tr>
            <tr>
              <td>Email Address</td>
              <td className="color-blur">{profile?.email}</td>
            </tr>
            <tr>
              <td>Phone Number</td>
              <td>
                <input
                  onChange={(e) => {
                    if (e.target.value.length > 11) return null;
                    return handleChange(e);
                  }}
                  style={{ outline: "none" }}
                  type="number"
                  name="mobile"
                  className={!edit ? "w-100 border-0" : "w-100"}
                  readOnly={!edit}
                  value={account?.mobile}
                />
              </td>
            </tr>
            <tr>
              <td>Address</td>
              <td>
                <input
                  onChange={handleChange}
                  maxLength={100}
                  style={{ outline: "none" }}
                  name="address"
                  className={!edit ? "w-100 border-0" : "w-100"}
                  readOnly={!edit}
                  value={account?.address}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="row">
          <div
            className={styles.picture + " col-lg-6 "}
            style={{ marginRight: "auto" }}
          >
            <Typography variant="h6">Passport</Typography>
            <Avatar
              variant="circular"
              style={{ width: "150px", height: "150px", marginBottom: "20px" }}
              src={profile?.passport?.Location}
            />
            <div className={styles.uploader}>
              <input
                type="file"
                onChange={(e) => handleUpload(e, "passport")}
                accept="image/*"
              />
              <Tooltip title="Edit Passport">
                <i className="fa fa-edit"></i>
              </Tooltip>
            </div>
          </div>
          <div className={styles["picture"] + " col-lg-6 "}>
            <Typography variant="h6">Identity</Typography>
            <Avatar
              variant="rounded"
              style={{ width: "150px", height: "150px", marginBottom: "20px" }}
              src={profile?.id?.Location}
            />
            <div className={styles["uploader"]}>
              <input
                type="file"
                onChange={(e) => handleUpload(e, "id")}
                accept="image/*"
              />
              <Tooltip title="Edit">
                <i className="fa fa-edit"></i>
              </Tooltip>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.userProfile,
  };
};

export default connect(mapStateToProps)(Index);
