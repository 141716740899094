import { Paper, Typography } from '@material-ui/core'
import React from 'react'
import Table from './Table'
import TableRefs from './TableRefs'
import styles from './../styles.module.css'
import { connect } from 'react-redux'

// 
function Index(props) {
    const { count, payouts, merchant } = props
    let total = 0
    merchant.map(item => {
        item.payment.map((pay, index) => {
            if (index === 0) {
                return total += parseInt(pay.price)
            }
        })

    })
    return (
        <>
            <div className="row-statistic">
                <div className=' statistic-item'>
                    <Typography className='mb-2'>Total Referrals</Typography>
                    <Typography variant="h6">{count}</Typography>
                </div>
                <div className='  statistic-item'>
                    <Typography className='mb-2'>Total Sales</Typography>
                    <Typography variant="h6">₦{total}</Typography>
                </div>
            </div>
            <Paper className={styles.paper}>
                <Table />
            </Paper>
            <br></br>
            <Paper className={styles.paper}>
                <TableRefs />
            </Paper>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        count: state.profile?.userProfile?.merchants?.length,
        merchant: state.profile.agentMerchant,
        // subscriptions: state.profile?.userProfile?.earnings?.length,
        // sales: state.profile?.userProfile.merchants.reduce((a, b) => Number(a.price) + Number(b.price), 0),
        payout: state.profile?.userProfile?.payouts
    }
}

export default connect(mapStateToProps)(Index)
