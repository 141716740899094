import React, { useState } from "react";
import { connect } from "react-redux";
import styles from "./login.module.css";
import { signUpExtAdmin } from "./../../../utility/axios-token-manager/auth";
import Flash from "./../../../utility/Flash";
import { loadStart, loadStop } from "./../../../redux/actions/loading";
// import {authLogin} from './../../../redux/actions/auth'
import { useDispatch } from "react-redux";
import logo from './../../../components/asset/img/Excite WLL.png'
//

const redirectToLogin= () => {
  setTimeout(()=>window.location.assign('/brand-dynamo/accounts?q=login'),2000)
};

//
const Setup = (props) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    token:"",
    password2:""
  });
  const reset=()=>{
      setValues(
          {
            email: "",
            password: "",
            token:"",
            password2:""
          }
      )
  }

  const handleChange = (e) => {
    values[e.target.name] = e.target.value;
    setValues({ ...values });
  };

  const handleSubmit = async () => {
    const email = values.email.trim().toLowerCase();
    const password = values.password;
    if(!email || !password) return Flash('error','Missing login details','',3000)
    try {
      dispatch(loadStart());
      const user = await signUpExtAdmin({ ...values,email: email.toLowerCase() });
      dispatch(loadStop());
      if (user) {
        Flash("success", "Account created", "", 5000);
        reset()
        redirectToLogin()
      }
      if (!user) {
        Flash("error", "Invalid token/email already in use", "", 5000);
        dispatch(loadStop());
      }
    } catch (error) {
      Flash("error", "Server error", "", 5000);
    }
  };
  return (
    <section className={styles.section}>
      <div className={"container " + styles.container}>
        <div className={styles.action}>
          <div className={styles.logoWrapper}>
            <a href="/">
              <img src={logo} alt='logo' />
            </a>
            <div>Set Up Access</div>
          </div>
          <div className={styles.form}>
            <div className={styles.inputWrapper }>
              <i className="fa fa-envelope"></i>
              <input
                onChange={(e) => handleChange(e)}
                type="text"
                placeholder="Token"
                name="token"
                value={values.token}
              />
            </div>
            <div className={styles.inputWrapper + " mt-4"}>
              <i className="fa fa-envelope"></i>
              <input
                onChange={(e) => handleChange(e)}
                type="text"
                placeholder="Email"
                name="email"
                value={values.email}
              />
            </div>
            <div className={styles.inputWrapper + " mt-4"}>
              <i className="fa fa-key"></i>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="password"
                name="password"
                value={values.password}
                onChange={(e) => handleChange(e)}
              />
              <span onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? "Hide" : "Show"}
              </span>
            </div>
            <div className={styles.inputWrapper + " mt-4"}>
              <i className="fa fa-key"></i>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="confirm password"
                name="password2"
                value={values.password2}
                onChange={(e) => handleChange(e)}
              />
              <span onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? "Hide" : "Show"}
              </span>
            </div>
          </div>
          <div className={styles.cta}>
            <button onClick={handleSubmit}>Create</button>
          </div>
          <div className={styles.create}>
            <p className={"text-center"}>
              Have an account ? <a href="/brand-dynamo/accounts?q=login">Login</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    isAuth: state.auth.token !== null,
    email: state.auth.email,
    loading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps)(Setup);
