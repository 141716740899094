import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
// import Button from '../shared/Button';
import {Card} from "@material-ui/core";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";



const handleLoginDate=(time)=>{
  if(time===0) return 'This User is yet to login'
  const day = new Date(time);
  return day.toLocaleString()
}

const lastLogin = (user)=>{
  const logins= user?.logins;
    if(logins?.length > 0){
      return logins[logins.length - 1]
    }else{
      return { lat: "", long: "", address: "" ,time:0}
    }
}



const SupervisedTable = ({ agent, agents }) => {

  const [data,setData]= useState([]);
  useEffect(()=>{
    const agentCode= agent?.agentCode;
    const supervised = agents?.filter(item=>item.supervisedBy===agentCode)
    setData(supervised)
  },[agent,agents])
  const columns = React.useMemo(
    () => [
      {
        name: "Name",
        sortable: true,
        cell: (row) => {
          return <div>{row.fullName}</div>;
        },
      },
      {
        name: "Phone Number",
        sortable: true,
        cell: (row) => {
          return <div>{row.mobile}</div>;
        },
      },
      {
        name: "Referrals",
        cell: (row) => {
          return <div>{row?.merchants?.length}</div>;
        },
        sortable: true,
      },
      {
        name: "Sales",
        cell: (row) => {
          const earns = row.earnings?.map((a)=>a.amount)?.reduce((a,b)=>Number(a)+Number(b),0);
          if(earns) return <div>{`NGN ${earns/100}`}</div>
          return <div>{`NGN 0`}</div>;
        },
        sortable: true,
      },
      {
        name: "Last Seen",
        cell: (row) => {
       
          return <div>{lastLogin(row) ? handleLoginDate(lastLogin(row).time) : "No data"}</div>;
        },
        sortable: true,
      },
    ],
    []
  );

  return (
    <>
      <div className="some">
        <Card>
          <DataTable
            title={<Typography variant="h6" className="mt-4 mb-4">Supervisions</Typography>}
            columns={columns}
            data={data}
            //   defaultSortField="title"
            sortable
            search
            responsive
            // onRowClicked={(row)=>handleRowClick(row)}
            highlightOnHover
            striped
            pagination
          />
        </Card>
      </div>
      {/* <AssignModal open={open} data={modalData} handleModal={handleModal}/> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    agents: state.profile.agents,
  };
};
export default connect(mapStateToProps)(SupervisedTable);
