import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Avatar,
  Button,
  MenuItem,
  Paper,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";
import styles from "./styles.module.css";
import Earnings from "../tables/Earnings";
import Referrals from "../tables/Refs";
import useAxios from "../../../utility/axios-token-manager/init";
import { setUpdate } from "./../../../redux/actions/profile";
import { loadStart, loadStop } from "./../../../redux/actions/loading";
// import SECTORS, { sections } from "./../sectors";
import Flash from "../../../utility/Flash";
import SupervisedAgents from "../sectors/tables/SupervisedAgents";

//
function Profile({ agents, id }) {
  const dispatch = useDispatch();
  //
  const [agent, setAgent] = useState({});
  const [supervisedOptions, setSupervisedOptions] = useState([])

  const lastLogin = () => {
    const logins = agent?.logins;
    if (logins?.length > 0) {
      return logins[logins.length - 1];
    } else {
      return { lat: "", long: "", address: "", time: 0 };
    }
  };

  const handleTotalSales = () => {
    const earns = agent.earnings.map(item => item.amount).reduce((a, b) => Number(a) + Number(b), 0);
    if (earns) return `${(earns / 100).toLocaleString()}`
    return `0`
  }
  useEffect(() => {
    const profile = agents.filter((item) => item._id === id);
    const supervisedList = agents.filter((item) => item.isLead === true && item._id !== id)
    // if(profile.length<1) return window.location.assign('/admin');
    setAgent(profile[0]);
    setSupervisedOptions(supervisedList)
  }, [agents, id]);

  const [show, setShow] = useState("profile");

  // Assign as Sales Lead
  const [isLead, setIsLead] = React.useState("");

  React.useEffect(() => {
    if (agent) {
      setIsLead(agent.isLead ? "Yes" : "No")
    }
  }, [agent])
  const handleIsLead = (event) => {
    setIsLead(event.target.value);
  };
  // Assign Supervisor to non leads
  const [supervisedBy, setSupervisedBy] = React.useState("");
  const handleSupervisedBy = (event) => {
    setSupervisedBy(event.target.value);
  };

  const handleUpdate = async () => {
    const data = {
      isLead: isLead === "Yes" ? true : false,
      supervisedBy: isLead === "Yes" ? "" : supervisedBy,
    };
    try {
      dispatch(loadStart());
      const response = await useAxios.put(
        `/excite/agent/update/ext/${agent._id}`,
        {
          ...data,
        }
      );
      dispatch(loadStop());
      if (response.data.code === 200) {
        dispatch(setUpdate(response.data.agents));
        Flash("success", "successful", "", 3000);
      }
    } catch (error) {
      dispatch(loadStop());
      Flash("error", error.message, "", 3000);
    }
  };

  const handleLoginDate = (time) => {

    if (time === 0) return 'This User is yet to login'
    const day = new Date(time);
    return day.toLocaleString();
  };
  //

  const handleGetSupervision = (code) => {
    const superDetails = agents.find((item => item.agentCode === code));
    if (superDetails) return superDetails.fullName;
    return ""
  }
  return (
    <>
      <div className="mb-4 d-flex">
        <div
          className={show === "profile" ? "active-filter " : "btn-filter"}
          style={{ marginRight: "20px" }}
        >
          <Button onClick={() => setShow("profile")}>Agent Profile</Button>
        </div>
        <div className={show === "earnings" ? "active-filter" : "btn-filter"}>
          <Button
            className={show === "earnings" ? styles.active : styles.btn}
            onClick={() => setShow("earnings")}
          >
            Sales & Comissions
          </Button>
        </div>
      </div>
      {show === "profile" ? (
        <>
          <Paper className={styles.paper}>
            <div>
              <Typography className="text-center" variant="h5">
                Sales Agent Profile
              </Typography>

              <div className="row">
                <div className="col-lg-4">
                  <Avatar
                    style={{ width: "100px", height: "100px" }}
                    src={agent?.passport?.Location}
                  />
                  {agent?.id?.Location ? (
                    <Button className="mt-2">
                      <a download href={agent?.id?.Location}>
                        Download ID
                      </a>
                    </Button>
                  ) : (
                    <Button className="mt-2">No ID</Button>
                  )}
                </div>
                <div className="col-lg-3 mt-4 mt-lg-auto">
                  <Typography variant="h6">Reference Code</Typography>
                  {/* <br></br> */}
                  <Button style={{ textTransform: 'none' }}>
                    {agent?.approve ? agent?.agentCode : "Pending"}
                  </Button>
                </div>
              </div>
              <div
                className="row"
                style={{ marginTop: "50px", marginBottom: "50px" }}
              >
                {/* ACTIONS */}
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Make Team Lead</FormLabel>
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          value={isLead}
                          onChange={handleIsLead}
                          row
                        >
                          <FormControlLabel
                            value={"Yes"}
                            control={<Radio checked={isLead === "Yes"} />}
                            label="Yes"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value={"No"}
                            checked={isLead === "No"}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {/* Supervisors select */}
                    <div>
                      {isLead === "No" ?
                        <>
                          <div className='mb-2 mt-4'>
                            <Typography>Supervised By: {handleGetSupervision(agent?.supervisedBy)}</Typography>
                          </div>
                          <TextField
                            value={supervisedBy}
                            select
                            fullWidth
                            className="mt-2 mb-3"
                            variant="outlined"
                            label="Change/Assign Supervisor"
                            onChange={handleSupervisedBy}
                          >
                            {supervisedOptions.map((item, index) => (
                              <MenuItem
                                value={item.agentCode}
                                key={index}
                              >{`${item.fullName}`}</MenuItem>
                            ))}
                          </TextField></> : null}
                    </div>
                  </div>
                  <div style={{ background: "#a7cc48", marginTop: '20px' }}>
                    <Button fullWidth onClick={() => handleUpdate()}>
                      Confirm
                    </Button>
                  </div>
                </div>
                {/*  */}
                <div className="col-lg-6">
                  <Typography variant="h6" className="mb-4">
                    Agent Location
                  </Typography>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Date</td>
                        <td>
                          {lastLogin()
                            ? handleLoginDate(lastLogin().time)
                            : "No data"}
                        </td>
                      </tr>
                      <tr>
                        <td>Longitude</td>
                        <td>{lastLogin() ? lastLogin().long : "No data"}</td>
                      </tr>
                      <tr>
                        <td>Latitude</td>
                        <td>{lastLogin() ? lastLogin().lat : "No data"}</td>
                      </tr>
                      <tr>
                        <td>Address</td>
                        <td>{lastLogin() ? lastLogin().address : "No data"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-4">
                <Typography variant="h6">Bio Data</Typography>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Full Name</td>
                      <td>{agent?.fullName}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{agent?.email}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{agent?.address}</td>
                    </tr>
                    <tr>
                      <td>Phone Number</td>
                      <td>{agent?.mobile}</td>
                    </tr>
                    <tr>
                      <td>NOK Full Name</td>
                      <td>{agent?.nok}</td>
                    </tr>
                    <tr>
                      <td>NOK Address</td>
                      <td>{agent?.nokAddress}</td>
                    </tr>
                    <tr>
                      <td>NOK Relationship</td>
                      <td>{agent?.nokRelationship}</td>
                    </tr>
                    <tr>
                      <td>NOK Phone Number</td>
                      <td>{agent?.nokPhone}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div>My profile {agent?.fullName}</div> */}
          </Paper>
          <Paper className={styles.paper + " mt-4"}>
            <div>
              <Typography variant="h6" className="mb-4">
                Bank Details
              </Typography>
              <table className="table">
                <tbody>
                  <tr>
                    <td>Bank Name</td>
                    <td>{agent?.accountDetails?.bank}</td>
                  </tr>
                  <tr>
                    <td>Account Number</td>
                    <td>{agent?.accountDetails?.accountNo}</td>
                  </tr>
                  <tr>
                    <td>Account Name</td>
                    <td>{agent?.accountDetails?.accountName}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>{" "}
        </>
      ) : null}
      {show === "earnings" ? (
        <>
          <div className="row-statistic">
            <div className=" statistic-item">
              <Typography className="mb-2">Total Referrals</Typography>
              <Typography variant="h6">{agent?.merchants?.length}</Typography>
            </div>
            <div className="  statistic-item">
              <Typography className="mb-2">Total Sales</Typography>
              <Typography variant="h6"> <span>&#8358; </span> {handleTotalSales()}</Typography>
            </div>
            <div className="  statistic-item">
              <Typography className="mb-2">Earnings</Typography>
              <Typography variant="h6">
                <span>&#8358; </span> 0
              </Typography>
            </div>
            <div className="  statistic-item">
              <Typography className="mb-2">Total Payout</Typography>
              <Typography variant="h6">
                <span>&#8358; </span> {0}
              </Typography>
            </div>
          </div>
          <div className={" mt-4 table-wrapper"}>
            <Earnings agent={agent} />
          </div>
          <div className={" mt-4 table-wrapper"}>
            <Referrals agent={agent} />
          </div>
          <div className={" mt-4 table-wrapper"}>
            {agent.isLead ?
              <SupervisedAgents agent={agent} /> : null}

          </div>
        </>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    agents: state?.profile?.agents,
  };
};

export default connect(mapStateToProps)(Profile);
