import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Avatar,
  Button,
  MenuItem,
  Paper,
  Typography,
  TextField,
} from "@material-ui/core";
import styles from "./styles.module.css";
import Earnings from "../tables/Earnings";
import Referrals from "../tables/Refs";
import useAxios from "../../../utility/axios-token-manager/init";
import { setUpdate } from "./../../../redux/actions/profile";
import { loadStart, loadStop } from "./../../../redux/actions/loading";
import SECTORS, { sections, subscriptionCycle, subscriptionPlan } from "./../sectors";
import Flash from "../../../utility/Flash";
import { host } from "../../../utility/host";
import { useLocation } from 'react-router-dom'

function Merchant(props) {
  const { agents, id, admin_token } = props
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [agent, setAgent] = useState({});
  const [merchant, setmerchant] = useState({})
  const [paymentData, setpaymentData] = useState({
    plan: '',
    cycle: 0
  })

  const handleTotalSales = () => {
    const earns = agent.earnings.map(item => item.amount).reduce((a, b) => Number(a) + Number(b), 0);
    if (earns) return `${(earns / 100).toLocaleString()}`
    return `0`

  }


  const getAllMerchantPayment = async () => {
    try {
      dispatch(loadStart());
      const headers = {
        'Authorization': `Bearer ${admin_token}`,
        'Accept': 'application/json'
      }
      const searchParams = new URLSearchParams(search).get('merchant')
      const response = await fetch(`${host}/auth/merchant/payment?id=${searchParams}`, { headers })
      const result = await response.json()
      const { success, message, payments } = result
      if (result && success === true) {
        // setAgent
        setmerchant(payments)
        dispatch(loadStop());
      }
      else {
        dispatch(loadStop());
        Flash("warning", message || 'Something went wrong', "", 3000);
      }
    } catch (error) {
      dispatch(loadStop());
      Flash("error", error.message, "", 3000);
    }
  }

  useEffect(() => {
    getAllMerchantPayment()
  }, [agents, id]);

  const [show, setShow] = useState("profile");


  const handleAssign = async (event) => {
    const { cycle, plan } = paymentData
    console.log('Plan', plan, 'cycle',cycle)
    if (cycle === '' || cycle === 0 || cycle === '0')
      return Flash("info", "Plan cycle is empty", "", 3000);
    if (plan === '')
      return Flash("info", "Plan is empty", "", 3000);
    // try {
    //   dispatch(loadStart());
    //   const response = await useAxios.put(
    //     `/excite/agent/sector/assign/${agent._id}`,
    //     {
    //       sector: SECTORS[sector]?.code,
    //     }
    //   );
    //   dispatch(loadStop());
    //   if (response.data.code === 200) {
    //     dispatch(setUpdate(response.data.agents));
    //     Flash("success", "Sector Assigned", "", 3000);
    //   }
    // } catch (error) {
    //   dispatch(loadStop());
    //   Flash("error", error.message, "", 3000);
    // }
  };

  //
  return (
    <>
      {show === "profile" ? (
        <>
          <Paper className={styles.paper}>
            <div>
              <Typography className="text-center" variant="h5">
                Merchant Profile
              </Typography>

              <div className="row">
                <div className="col-lg-4">
                  <Avatar
                    style={{ width: "100px", height: "100px" }}
                    src={agent?.passport?.Location}
                  />
                </div>
              </div>
              <div
                className="row"
                style={{ marginTop: "50px", marginBottom: "50px" }}
              >
                <div className="col-lg-6">
                  {agent?.sector ? (
                    <Typography variant="h6" className='mb-4'>
                      Curent Plan {agent?.sector}
                    </Typography>
                  ) : (
                    <Typography style={{ color: "red" }} variant="body2" className='mb-4'>
                      Add Subscription
                    </Typography>
                  )}
                  <TextField
                    value={paymentData.plan}
                    select
                    fullWidth
                    className="mt-2 mb-3"
                    variant="outlined"
                    label={"Add Plan"}
                    onChange={(event) => setpaymentData({ ...paymentData, plan: event.target.value })}
                  >
                    {subscriptionPlan.map((item, index) => (
                      <MenuItem
                        value={item.name}
                        key={index}
                      >{`${item.name}`}</MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="col-lg-6">
                  {merchant?.sector ? (
                    <Typography variant="h6" className='mb-4'>
                      Current Plan Cycle {agent?.sector}
                    </Typography>
                  ) : (
                    <Typography style={{ color: "red" }} variant="body2" className='mb-4'>
                      Add Plan Cycle
                    </Typography>
                  )}
                  <TextField
                    value={paymentData.cycle}
                    select={true}
                    fullWidth
                    className="mt-2 mb-3"
                    variant="outlined"
                    label={"Add Plan Cycle"}
                    onChange={(event) => setpaymentData({ ...paymentData, cycle: event.target.value })}
                  >
                    {subscriptionCycle.map((item, index) => (
                      <MenuItem
                        value={item.duration}
                        key={index}
                      >{`${item.name}`}</MenuItem>
                    ))}
                  </TextField>
                  <div style={{ background: "#a7cc48" }}>
                    <Button fullWidth onClick={() => handleAssign()}>
                      Add Plan
                    </Button>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <Typography variant="h6">Bio Data</Typography>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Full Name</td>
                      <td>{merchant?.fullname}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{merchant?.email}</td>
                    </tr>
                    <tr>
                      <td>Phone Number</td>
                      <td>{merchant?.phone}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div>My profile {agent?.fullName}</div> */}
          </Paper>{" "}
        </>
      ) : null}
      {show === "earnings" ? (
        <>
          <div className="row-statistic">
            <div className=" statistic-item">
              <Typography className="mb-2">Total Referrals</Typography>
              <Typography variant="h6">{agent?.merchants?.length}</Typography>
            </div>
            <div className="  statistic-item">
              <Typography className="mb-2">Total Sales</Typography>
              <Typography variant="h6"> <span>&#8358; </span> {handleTotalSales()}</Typography>
            </div>
            <div className="  statistic-item">
              <Typography className="mb-2">Earnings</Typography>
              <Typography variant="h6">
                <span>&#8358; </span> 0
              </Typography>
            </div>
            <div className="  statistic-item">
              <Typography className="mb-2">Total Payout</Typography>
              <Typography variant="h6">
                <span>&#8358; </span> {0}
              </Typography>
            </div>
          </div>
          <div className={" mt-4 table-wrapper"}>
            <Earnings agent={agent} />
          </div>
          <div className={" mt-4 table-wrapper"}>
            <Referrals agent={agent} />
          </div>
        </>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    agents: state?.profile?.agents,
    merchants: state.profile?.merchants,
    admin_token: state.auth?.token,
  };
};

export default connect(mapStateToProps)(Merchant);
