import React from "react";
import DataTable from "react-data-table-component";
// import Button from '../shared/Button';
import { Card, Button, TextField, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ExportCSV from "./../../ExcelDownloader";

function handleTotalSales(agent) {
  let earns = agent.earnings
    .map((item) => item.amount)
    .reduce((a, b) => Number(a) + Number(b), 0);
  if (earns) {
    return `${(earns / 100).toLocaleString()}`;
  } else {
    return `0`;
  }
}
const handleGetSupervision = (code, agents) => {
  const superDetails = agents.find((item) => item.agentCode === code);
  if (superDetails) return superDetails.fullName;
  return "";
};

const Table = ({ agents, exportData }) => {
  const [dataEx, setDataEx] = React.useState([]);

  React.useEffect(() => {
  
    setDataEx(exportData);
  }, [exportData]);
  //Filter
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = agents?.filter(
    (item) =>
      item.fullName &&
      item.fullName.toLowerCase().includes(filterText.toLowerCase())
  );

  //

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <TextField
          id="search"
          type="text"
          aria-label="Search Input"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          label="Search by name"
        />
        <Button type="button" onClick={handleClear}>
          X
        </Button>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  //
  const columns = React.useMemo(
    () => [
      {
        name: "Fullname",
        sortable: true,
        cell: (row) => {
          return <div>{row.fullName?.toUpperCase()}</div>;
        },
      },
      {
        name: "Phone Number",
        selector: "mobile",
        sortable: true,
      },
      {
        name: "Agent ID",
        cell: (row) => {
          if (row.approve) {
            return <div>{row?.agentCode}</div>;
          } else {
            return <div>Pending Approval</div>;
          }
        },
      },
      {
        name: "Referrals",
        cell: (row) => {
          return <div>{row?.merchants?.length || 0}</div>;
        },
      },
      {
        name: "Action",
        cell: (row) => {
          return (
            <Link to={`/brand-dynamo?agent=${row._id}`}>
              <Button>View</Button>
            </Link>
          );
        },
      },
    ],
    []
  );
  const today = new Date().toLocaleDateString();
  const fileName = `All Agents Updates: ${today}`;
  return (
    <>
      <div className="some">
        <Paper className="mb-4">
          <div className="p-2">
            {dataEx.length > 0 ? (
              <ExportCSV csvData={dataEx} fileName={fileName}></ExportCSV>
            ) : null}
          </div>
        </Paper>
        <Card>
          <DataTable
            title="All Agents"
            columns={columns}
            data={filteredItems}
            sortable
            search
            responsive
            highlightOnHover
            striped
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
          />
        </Card>
      </div>
      {/* <AssignModal open={open} data={modalData} handleModal={handleModal}/> */}
    </>
  );
};

const mapStateToProps = (state) => {
  let dtEx = state.profile?.agents;

  const dataEx = () =>
    dtEx?.map((item) => {
      item["Total Referrals"] = item.merchants?.length;
      item["Total Sales"] = handleTotalSales(item);
      item["Supervisor"] = handleGetSupervision(item.supervisedBy, dtEx);
      const { fullName, Supervisor, agentCode } = item;
      return {
        NAME: fullName,
        "REF CODE": agentCode,
        SUPERVISOR: Supervisor,
        "TOTAL REFERRALS": item["Total Referrals"],
        "TOTAL SALES": item["Total Sales"],
      };
    });
  return {
    agents: state.profile?.agents,
    exportData: dataEx(),
  };
};
export default connect(mapStateToProps)(Table);
