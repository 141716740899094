import useAxios from "../../utility/axios-token-manager/init";
import { loadStart, loadStop } from "./loading";


export const getUserProfile = () => async (dispatch) => {
  dispatch(loadStart());
  try {
    const response = await useAxios.get("/sales-agent/my-profile");
    dispatch(loadStop());
    const { success, profile, message, merchants } = response.data
    if (success === true) {
      return dispatch({
        type: "SET_PROFILE",
        payload: { profile, merchants },
      });
    } else {
      return dispatch({
        type: "SET_PROFILE",
        payload: {},
      });
    }
  } catch (error) {
    dispatch(loadStop());
    return dispatch({
      type: "SET_PROFILE",
      payload: {},
    });
  }
};
export const getAgents = (sector) => async (dispatch) => {
  dispatch(loadStart());
  const getURL = sector ? "/excite/agent/get-all/ext04" : "/excite/agent/get-all"
  try {
    const response = await useAxios.get(getURL);
    if (response.data.code === 200) {
      return dispatch({
        type: "SET_ALL",
        payload: { agents: response.data.agents, feedbacks: response.data.feedbacks },
      });
    } else {
      return dispatch({
        type: "SET_ALL",
        payload: { agents: [], feedbacks: [] },
      });
    }
  } catch (error) {
    dispatch(loadStop());
    return dispatch({
      type: "SET_ALL",
      payload: { agents: [], feedbacks: [] },

    });
  }
};

export const getMerchants = (page, limit, headers) => async (dispatch) => {
  dispatch(loadStart());
  // const getURL = `/auth/merchants/${page}/${limit}`
  const getURL = `/auth/merchants/1/30`
  try {
    const response = await useAxios.get(getURL, { headers });
    dispatch(loadStop());
    const { success, merchants, message } = response.data
    if (success === true) {
      return dispatch({
        type: "MERCH_ALL",
        payload: { merchants: merchants, message: message, success: success },
      });
    } else {
      return dispatch({
        type: "MERCH_ALL",
        payload: { merchants: [], message: message, success: success },

      });
    }
  } catch (error) {
    dispatch(loadStop());
    return dispatch({
      type: "MERCH_ALL",
      payload: { merchants: [], message: error.message, success: false },

    });
  }
};

export const setUpdate = (data) => {
  return {
    type: "SET_UPDATE",
    payload: data,
  };
};
export const makeFeedBack = (data) => {
  return {
    type: "MAKE_FEEDBACK",
    payload: data,
  };
};