export  const navItems = [
   
    {
      category: "Overview",
      iClass:'bi bi-bullseye',
      url:"/brand-dynamo"
    },
    {
      category: "Team Leads",
      iClass:'bi bi-broadcast',
      url:"/brand-dynamo/filter?leads=true"
    },
    {
      category: "Field Agents",
      iClass:'bi bi-broadcast',
      url:"/brand-dynamo/filter?leads=false"
    },
  ];