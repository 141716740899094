import React from "react";
import Layout from "../../layout/adminExt/Layout";
import Table from "./tables/ReactDataTable";
import LeadsTable from "./tables/LeadsTable";
import NonLeadsTable from "./tables/NonLeadsTable";
import { useQueryParam } from "use-query-param";
import { connect } from "react-redux";

//
function Teams({leads,nonLeads}) {

  const { queryParams } = useQueryParam(window.location.href);
  const queries = queryParams.leads;
  const [type,setType]= React.useState('false');
  React.useEffect(()=>{
    setType(queries)
  },[queries])
  if(!queries) return window.location.assign('/brand-dynamo');
  switch (type) {
    case "true":
      return (
        <Layout>
          <LeadsTable sector={leads}/>
        </Layout>
      );
    case "false":
      return (
        <Layout>
          <NonLeadsTable sector={nonLeads}/>
        </Layout>
      );
    default:
      return (
        <Layout>
          <Table sector={[]}/>
        </Layout>
      );
  }  
}
const mapStateToProps=(state)=>{
  const leads = state.profile?.agents?.filter(item=>item.isLead===true)
  const nonLeads = state.profile?.agents?.filter(item=>item.isLead===false)
  return{
    leads,
    nonLeads
  }
}
export default connect(mapStateToProps)(Teams)
