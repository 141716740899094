import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./login.module.css";
import { signIn } from "./../../../utility/axios-token-manager/auth";
import Flash from "./../../../utility/Flash";
import { loadStart, loadStop } from "./../../../redux/actions/loading";
// import {authLogin} from './../../../redux/actions/auth'
import { useDispatch } from "react-redux";
import logo from "./../../../components/asset/img/Excite WLL.png";
// import { Button } from "@material-ui/core";
import Geocode from "react-geocode";

const redirectToMyDashboard = (user) => {
  if (typeof window !== "undefined") {
    switch (user) {
      case "EX20AG":
        return window.location.assign("/console");
      default:
        break;
    }
  }
};

//
const Login = (props) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [loginInputs, setLoginputs] = useState({
    email: "",
    password: "",
  });
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  // const [status, setStatus] = useState(null);
  const [address, setAddress] = useState("");

  const handleChange = (e) => {
    loginInputs[e.target.name] = e.target.value;
    setLoginputs({ ...loginInputs });
  };

  const getLocation = () => {
    if (!navigator.geolocation) {
      return Flash(
        "error",
        "Geolocation not supported on this browser!",
        "",
        3000
      );
    }
    // setStatus("Locating...");
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        // setStatus(null);
        setLat(position.coords.latitude);
        setLong(position.coords.longitude);
        Geocode.setLanguage("en");
        Geocode.setLocationType("ROOFTOP");
        Geocode.setApiKey(process.env.REACT_APP_GEOMAPPING);
        const response = await Geocode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        );
        const addr = response?.results[0]?.formatted_address;
        if (addr) return setAddress(addr);
        // ********** END: Get Location ********
      },
      () => {
        Flash(
          "error",
          "Location coordinates could not be retrieved!",
          "",
          3000
        );
      }
    );
  };
  useEffect(() => {
    getLocation();
  }, []);

  const handleSubmit = async () => {
    const email = loginInputs.email.trim();
    const password = loginInputs.password;
    if (!email || !password)
      return Flash("warning", "Missing login details", "", 3000);

    // *********** Get Location ***********
    try {
      dispatch(loadStart());
      const user = await signIn({
        email: email.trim(),
        password,
        lat,
        long,
        address: address,
      });
      dispatch(loadStop());
      const { message, success, token } = user
      if (success === true) {
        Flash("success", message, "", 5000);
        return redirectToMyDashboard(user.user?.userType);
      }
      else {
        Flash("warning", message, "", 5000);
        dispatch(loadStop());
      }
    } catch (error) {
      return Flash("error", error.message, "", 5000);
    }
  };
  return (
    <section className={styles.section}>
      <div className={"container " + styles.container}>
        <div className={styles.action}>
          <div className={styles.logoWrapper}>
            <a href="/">
              <img src={logo} alt="logo" />
            </a>
            <div>Login to your account</div>
          </div>
          <div className={styles.form}>
            <div className={styles.inputWrapper}>
              <i className="fa fa-envelope"></i>
              <input
                onChange={(e) => handleChange(e)}
                type="text"
                placeholder="Email"
                name="email"
                value={loginInputs.email}
              />
            </div>
            <div className={styles.inputWrapper + " mt-4"}>
              <i className="fa fa-key"></i>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="password"
                name="password"
                value={loginInputs.password}
                onChange={(e) => handleChange(e)}
              />
              <span onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? "Hide" : "Show"}
              </span>
            </div>
          </div>
          <div className={styles.cta}>
            <button onClick={handleSubmit}>Login</button>
          </div>
          <p className={"text-center  mt-2 " + styles.forgot}>
            Forgot password ? click <a href="/password-reset">here</a>
          </p>
          <div className={styles.create}>
            <p className={"text-center"}>
              Don't have an account ? <a href="/accounts?q=sign-up">Sign Up</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    isAuth: state.auth.token !== null,
    is_seller: state.auth.is_seller,
    email: state.auth.email,
    loading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps)(Login);
