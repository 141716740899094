import React from 'react'
import Layout from './../layout/user/Layout'
import  Feedback  from './console/feedback/Feedback'
import { Trigger } from './console/feedback/Trigger'
import Console from './console/Index'


export default function Landing() {
    return (
        <div>
            <Layout>
                <Console />
                <Feedback />
                <Trigger />
            </Layout>
        </div>
    )
}
