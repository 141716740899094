import React, { useState } from "react";
import { connect } from "react-redux";
import styles from "./login.module.css";
import Flash from "../../../utility/Flash";
import { loadStart, loadStop } from "../../../redux/actions/loading";
import { useDispatch } from "react-redux";
import logo from "./../../../components/asset/img/Excite WLL.png";
import useAxios from "../../../utility/axios-token-manager/init";






// validation
function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
//
const Login = (props) => {
  const dispatch = useDispatch();
  const [loginInputs, setLoginputs] = useState({ email: "" });

  const handleChange = (e) => {
    loginInputs[e.target.name] = e.target.value;
    setLoginputs({ ...loginInputs });
  };


  const handleSubmit = async () => {
    const email = loginInputs.email.trim().toLowerCase();
    if (!email.trim() || !validateEmail(email.trim()))
      return Flash("warning", "Enter a valid email address", "", 3000);
    try {
      dispatch(loadStart());
      const response = await useAxios.post('/sales-auth/reset', { email: email.toLowerCase() });
      const { message, success } = response.data
      if (success === true) {
        Flash('success', message, '', 3000)
        dispatch(loadStop());
      }
      else {
        Flash('warning', message, '', 3000)
        dispatch(loadStop());
      }

      // if(response.data.code===200){
      // return  Flash('success','Reset link has been sent to your email','',3000)
      // }
      // if(response.data.code===500){
      // return  Flash('success','Server/Network error','',3000)
      // }
      // if(response.data.code===404){
      // return  Flash('error',response.data.message,'',3000)
      // }
      // if(response.data.code===401){
      // return  Flash('error',response.data.message,'',3000)
      // }

    } catch (error) {
      return Flash("error", error.message, "", 5000);
    }
  };
  return (
    <section className={styles.section}>
      <div className={"container " + styles.container}>
        <div className={styles.action}>
          <div className={styles.logoWrapper}>
            <a href="/">
              <img src={logo} alt="logo" />
            </a>
            <div>Reset Password</div>
          </div>
          <div className={styles.form}>
            <div className={styles.inputWrapper}>
              <i className="fa fa-envelope"></i>
              <input
                onChange={(e) => handleChange(e)}
                type="text"
                placeholder="Enter your email address"
                name="email"
                value={loginInputs.email}
              />
            </div>
          </div>
          <div className={styles.cta}>
            <button onClick={handleSubmit}>Reset</button>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    isAuth: state.auth.token !== null,
    is_seller: state.auth.is_seller,
    email: state.auth.email,
    loading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps)(Login);
