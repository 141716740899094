import React from 'react'
import './modal.css'

export default function SubscriptionModal() {
    return (
        <div className='modal'>
            <div>SubscriptionModal</div>
        </div>
    )
}

