export  const navItems = [
   
    {
      category: "Dashboard",
      iClass:'bi bi-graph-up',
      url:"/console"
    },
    {
      category: "Sales",
      iClass:'bi bi-briefcase-fill',
      url:"/console?q=sales"

    },
];
  // test comment

    // {
    //   category: "Account Setting",
    //   iClass:'bi bi-cash-stack',
    //   url:"/console?q=setup"

    // },