import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
// import Button from '../shared/Button';
import {Card, Button} from "@material-ui/core";
import { connect } from "react-redux";
import {Link} from 'react-router-dom'

const Table = ({ agents , sector}) => {
  const [data,setData] = useState([]);

  useEffect(()=>{
    setData(agents.filter(item=>`${item.sector}`===`${sector}`))
  },[agents,sector])
  const columns = React.useMemo(
    () => [
      {
        name: "Fullname",
        sortable: true,
        cell: (row) => {
          return <div>{row.fullName?.toUpperCase()}</div>;
        },
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
      },
      {
        name: "Phone Number",
        selector: "mobile",
        sortable: true,
      },
      {
        name: "Agent ID",
        cell: (row) => {
          if (row.approve) {
            return (
              <div>
                {row?.agentCode}
              </div>
            );
          } else {
            return <div>Pending Approval</div>;
          }
        },
      },
      {
        name: "Action",
        cell: (row) => {
            return <Link to={`/admin?agent=${row._id}`}><Button>View</Button></Link>;
          }
        },
    ],
    []
  );

  return (
    <>
      <div className="some">
        <Card>
          <DataTable
            title={`Sales Agents ${sector}`}
            columns={columns}
            data={data}
            //   defaultSortField="title"
            sortable
            search
            responsive
            // onRowClicked={(row)=>handleRowClick(row)}
            highlightOnHover
            striped
            pagination
          />
        </Card>
      </div>
      {/* <AssignModal open={open} data={modalData} handleModal={handleModal}/> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    agents: state.profile.agents,
  };
};
export default connect(mapStateToProps)(Table);
