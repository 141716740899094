import React from "react";
import Layout from "../layout/admin/Layout";
import Table from "./tables/ReactDataTable";
import { useQueryParam } from "use-query-param";
import Profile from "./agent/Profile";
import Merchant from "./merchants/Merchant";


export default function Index() {
  const { queryParams } = useQueryParam(window.location.href);
  if (queryParams.agent) return (
    <Layout>
      {/* <Table /> */}
      <Profile id={queryParams.agent} />
    </Layout>
  );
  else if (queryParams.merchant) {
    return (
      <Layout>
        <Merchant id={queryParams.merchant} />
      </Layout>
    )
  }
  else {
    return (
      <Layout>
        {/* <Profile id={queryParams.agent} /> */}
        <Table />
      </Layout>
    )
  }
}
