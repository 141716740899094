import React from "react";
import { connect, useDispatch } from "react-redux";
import { makeFeedBack } from "../../../../redux/actions/profile";
import styles from "./style.module.css";
import { Typography } from "@material-ui/core";

//
export const Trigger = ({ feedback }) => {
  const dispatch = useDispatch();

  return (
    <div
      onClick={() => dispatch(makeFeedBack(true))}
      className={styles["wrapper"]}
    >
      <div className={styles["whats-app"]}>
        <Typography style={{ fontSize: "13px", color: "#000" }} variant="body1">
          Market Feedback
        </Typography>
        <i className="bi bi-card-list"></i>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  feedback: state?.profile?.makeFeedBack,
});

export default connect(mapStateToProps, null)(Trigger);
