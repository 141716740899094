export  const navItems = [
   
    {
      category: "Overview",
      iClass:'bi bi-bullseye',
      url:"/admin"
    },
    {
      category: "Sector One",
      iClass:'bi bi-broadcast',
      url:"/filter?sector=1"
    },
    {
      category: "Sector Two",
      iClass:'bi bi-broadcast',
      url:"/filter?sector=2"
    },
    {
      category: "Sector Three",
      iClass:'bi bi-broadcast',
      url:"/filter?sector=3"
    },
    {
      category: "Sector Four",
      iClass:'bi bi-broadcast',
      url:"/filter?sector=4"
    },
    {
      category: "Sector Five",
      iClass:'bi bi-broadcast',
      url:"/filter?sector=5"
    }
  ];