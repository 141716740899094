import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { ListItemSecondaryAction } from '@material-ui/core';
// import { Checkbox } from '@material-ui/core';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

const handleLoginDate=(time)=>{
    const day = new Date(time);
    return day.toLocaleString()
  }

function Feedbacks({feedbacks}) {
    
  const classes = useStyles();

  return (
    <List className={classes.root}>
        {feedbacks?.map((item,index)=><React.Fragment key={index}>
      <ListItem alignItems="flex-start" >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={item?.reporter?.id?.Location} />
        </ListItemAvatar>
        <ListItemText
          primary={<div className='d-lg-flex justify-content-lg-between mb-2'><p><i className='bi bi-geo-alt'></i>{item?.location} </p> <p className='ml-lg-4'>{handleLoginDate(item?.date)}</p></div>}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {item?.reporter?.fullName}
              </Typography>
              {` - ${item?.report}`}
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
            <div className='mt-4'>
            <i className='bi bi-eye'></i>
              <i className='bi bi-trash'></i>
            </div>
            </ListItemSecondaryAction>
      </ListItem>
      
      <Divider variant="inset" component="li" /></React.Fragment>)}
    </List>
  );
}

const mapStateToProps=(state)=>{
    return{
        feedbacks:state?.profile?.feedbacks
    }
}

export default connect(mapStateToProps)(Feedbacks)
